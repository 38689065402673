<template>
  <div>
    <div v-if="isLeadOrNSO" class="p-4 flex justify-between items-center">
      <div class="flex gap-4 items-center">
        <span>RFPS</span>
        <fwb-select
          v-model="tab"
          @change="toggleTab()"
          :options="options"
          class="text-white bg-gray-700"
        />
        <input
          type="search"
          v-model="search"
          class="bg-gray-700 rounded px-4"
          placeholder="Search..."
        />
      </div>
    </div>
    <div class="flex flex-col rounded-lg pb-4 relative bg-[#23262F]">
      <div v-if="!loading && isLeadOrNSO" class="text-white">
        <div v-if="tab === 3">
          <p class="text-xl px-4 py-2">Declined by Crewfare</p>
          <List
            v-if="rfpsDeclinedByCrewfare.length > 0"
            :rfps="rfpsDeclinedByCrewfare"
          />
          <p v-else class="my-4 text-center">
            There are no RFPs in this category at the moment.
          </p>
          <p class="text-xl px-4 py-2">Declined by the Hotel</p>
          <List
            v-if="rfpsDeclinedByHotel.length > 0"
            :rfps="rfpsDeclinedByHotel"
          />
          <p v-else class="my-4 text-center">
            There are no RFPs in this category at the moment.
          </p>
        </div>
        <div v-else>
          <List v-if="rfps?.length > 0" :rfps="rfps" />
          <p v-else class="my-4 text-center">
            There are no RFPs in this category at the moment.
          </p>
        </div>
      </div>
      <div v-if="!loading && !isLeadOrNSO" class="text-white">
        <List v-if="rfps?.length > 0" :rfps="rfps" />
        <p v-else class="my-4 text-center">
          There are no RFPs in this category at the moment.
        </p>
      </div>
      <div class="flex justify-center py-4">
        <span
          v-if="loading"
          class="material-symbols-rounded animate-spin text-[32px]"
        >
          autorenew
        </span>
      </div>
      <Pagination
      :actual="currentPage"
      :max="per_page"
      :total="total"
      @togglePage="togglePage"
    />
    </div>
  </div>
</template>

<script>
import List from "./List.vue";
import { FwbSelect } from "flowbite-vue";
import { ProposalStatusType } from "@crewfare/commons/src/model";
import { proposalsApi } from "@/utils/apis/proposalsApi";
import Pagination from "@/components/default/Pagination.vue";
import Input from "@/components/ui/atoms/Input.vue";
import { debounce } from "@/utils/formatter";

export default {
  props: ["showViewAll"],
  components: {
    List,
    FwbSelect,
    Pagination,
    Input
  },
  data() {
    return {
      options: [
        {
          value: ProposalStatusType.ALL,
          name: "All RFPs",
        },
        {
          value: ProposalStatusType.PROGRESS,
          name: "RFPs in Progress",
        },
        {
          value: ProposalStatusType.COMPLETED,
          name: "Completed RFPs",
        },
        {
          value: ProposalStatusType.DECLINED,
          name: "Declined RFPs",
        },
      ],
      tab: ProposalStatusType.ALL,
      rfps: [],
      loading: false,
      status: "All",
      search: "",
      currentPage: 0,
      total: 0,
      signals: {
        list: {
          controller: new AbortController(),
          count: 0,
        }
      },
      per_page: 5
    };
  },
  async mounted() {
    document.title = `Launchpad - Crewfare - RFPs`;
    this.getData();
  },
  computed: {
    account() {
      return this.$store.state.account;
    },
    rfpsFound() {
      return this.rfps.length;
    },
    rfpsDeclinedByCrewfare() {
      return this.rfps.filter((rfp) => {
        return rfp.status.toLowerCase() === "declined by crewfare";
      });
    },
    rfpsDeclinedByHotel() {
      return this.rfps.filter((rfp) => {
        return rfp.status.toLowerCase() === "declined by hotel";
      });
    },
    isLeadOrNSO() {
      return ["nso", "lead manager"].includes(
        this.account.type.toLowerCase()
      );
    },
    isGroupManager() {
      return ["group manager"].includes(this.account.type.toLowerCase());
    },
    isContact() {
      return ["contact"].includes(this.account.type.toLowerCase());
    },
  },
  watch: {
    tab() {
      this.getData();
    },
    account() {
      this.getData();
    },
    search() {
      this.getData();
    }
  },
  methods: {
    togglePage(page) {
      this.currentPage = page;
      this.getData();
    },
    async listProposal(listCount, signal) {
      let proposalOptions = {
        filters: JSON.stringify({
          status: this.tab,
        }),
        search: this.search,
        page: this.currentPage,
        per_page: this.per_page,
      }
      const proposals = await proposalsApi.list(proposalOptions, signal).then((res) => res.data || []);
      if(listCount == this.signals.list.count){
        this.rfps = proposals.data;
        this.total = proposals.total;
        this.loading = false;
      }
      else{
        console.log("List count mismatch", listCount, this.signals.list.count)
      }
    },
    getData: debounce(function(){
      this.signals.list.count++;
      this.signals.list.controller = new AbortController();
      const currentCount = this.signals.list.count;
      this.loading = true;
      this.listProposal(currentCount, this.signals.list.controller.signal);
    }, 1000),
  },
};
</script>
