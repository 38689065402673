<template>
  <div class="bg-gray-800 w-full flex flex-col stage-height text-white">
    <div class="p-4 flex justify-between bg-gray-900 items-center">
      <div class="flex gap-4 h-[42px] items-center">
        <router-link
          :to="{ name: 'hotelsList' }"
          class="material-symbols-rounded hover:text-crewfareGreen"
        >
          chevron_left
        </router-link>
        <h1 class="text-xl">Hotels - {{ title }}</h1>
      </div>
    </div>
    <form
      v-show="!this.$route.params.id || (this.$route.params.id && !loadingData)"
      @submit.prevent="save"
      class="flex flex-col gap-4 text-white w-full p-4"
    >
      <div class="flex flex-col gap-1 w-full">
        <label class="text-white font-bold">Name</label>
        <input
          type="text"
          v-model="name"
          class="w-full bg-gray-700 rounded px-4"
        />
      </div>
      <div class="flex flex-row gap-4">
        <div class="flex flex-col gap-1 w-full">
          <label class="text-white font-bold">Hotel ID</label>
          <input
            type="text"
            v-model="hotel_id"
            class="w-full bg-gray-700 rounded px-4"
          />
        </div>
        <div class="flex flex-col gap-1 w-full">
          <label class="text-white font-bold">Chain</label>
          <input
            type="text"
            v-model="chain"
            class="w-full bg-gray-700 rounded px-4"
          />
        </div>
        <div class="flex flex-col gap-1 w-full">
          <label class="text-white font-bold">Rooms</label>
          <input
            type="text"
            v-model="rooms"
            class="w-full bg-gray-700 rounded px-4"
          />
        </div>
      </div>
      <div class="flex flex-col gap-1 w-full border-t pt-4">
        <label class="text-white font-bold">Address</label>
        <GMapAutocomplete
          ref="gmapAutocomplete"
          placeholder="Search Location"
          class="w-full bg-gray-700 rounded px-4 py-2 border border-gray-500"
          @place_changed="(place) => setPlace(place)"
        />
      </div>
      <div class="flex flex-col gap-1 w-full border-t border-white pt-4">
        <label class="text-white font-bold">Manager</label>
        <input
          type="text"
          placeholder="Search user"
          v-model="search"
          class="w-full bg-gray-700 rounded px-4"
        />
        <div
          v-if="accountsFiltered.length > 0"
          class="flex flex-col gap-2 mt-2"
        >
          <div
            v-for="account in accountsFiltered"
            :key="account.id"
            @click="addManager(account)"
            class="px-4 py-2 flex border border-gray-50 gap-4 items-center rounded hover:bg-gray-600 cursor-pointer"
          >
            <span
              class="rounded-full w-[34px] h-[34px] bg-crewfareGreen text-gray-900 flex justify-center items-center"
            >
              {{ getInitials(account.data().name) }}
            </span>
            <div class="flex flex-col gap-1">
              <p>{{ account.data().name }}</p>
              <p>{{ account.data().email }}</p>
            </div>
          </div>
        </div>

        <div v-if="manager.length > 0" class="flex flex-col gap-2 mt-2">
          <div
            v-for="(account, index) in manager"
            :key="account.id"
            class="px-4 py-2 flex border border-gray-50 gap-4 items-center rounded hover:bg-gray-600 cursor-pointer"
          >
            <span
              class="rounded-full w-[34px] h-[34px] bg-crewfareGreen text-gray-900 flex justify-center items-center"
            >
              {{ getInitials(account.name) }}
            </span>
            <div class="flex flex-col gap-1 flex-grow">
              <p>{{ account.name }}</p>
              <p>{{ account.email }}</p>
            </div>
            <div>
              <span
                @click="removeManager(index)"
                class="cursor-pointer text-red-400 material-symbols-rounded"
              >
                close
              </span>
            </div>
          </div>
        </div>
      </div>
      <Contacts :contacts="contacts" :hotel="hotel" />
      <div class="flex gap-4 mt-4 justify-between">
        <router-link :to="{ name: 'hotelsList' }" class="cancel-button">
          Cancel
        </router-link>
        <button :disabled="!valid || loading" class="green-button">
          <span
            v-if="loading"
            class="material-symbols-rounded animate-spin flex items-center"
          >
            autorenew
          </span>
          <span v-else> Save </span>
        </button>
      </div>
    </form>
    <Loading v-if="this.$route.params.id && loadingData" />
  </div>
</template>

<script>
import { firestore } from "@/utils/firebase";
import {
  doc,
  setDoc,
  addDoc,
  getDocs,
  query,
  where,
  collection,
  getDoc,
} from "firebase/firestore";
import { ListItem, ListItemSkeleton } from "@/components/hotels";
import router from "@/router";
import { save } from "@/utils/contact.jsx";
import Contacts from "@/components/contacts/Contacts.vue";
import {hasUniqueElementsInArray, isValidEmail} from '@crewfare/utils'
import Loading from "@/components/default/Loading.vue";

export default {
  components: {
    ListItem,
    Contacts,
    ListItemSkeleton,
  },
  computed: {},
  data() {
    return {
      lat: "",
      lng: "",
      search: "",
      hotel: {},
      hotelName: "",
      loading: false,
      loadingData: false,
      hotel_id: "",
      name: "",
      chain: "",
      rooms: "",
      addr: "",
      state: "",
      accounts: [],
      accountsFiltered: [],
      manager: [],
      contacts: [],
    };
  },
  computed: {
    title() {
      if (this.$route.params.id) {
        return this.hotelName;
      } else {
        return "Add Hotel";
      }
    },
    hasValidEmails(){
      return this.contacts.every(contact => isValidEmail(contact.email))
    },
    valid() {
      return (
        this.name.length > 0 && 
        this.addr.length > 0 && 
        this.state.length > 0 && 
        hasUniqueElementsInArray(this.contacts.map(contact => contact?.email?.toLowerCase())) &&
        this.hasValidEmails
      );
    },
  },
  watch: {
    search() {
      if (this.search.length === 0) {
        this.accountsFiltered = [];
      } else {
        this.accountsFiltered = this.accounts
          .filter(
            (account) =>
              account
                .data()
                .name.toLowerCase()
                .indexOf(this.search.toLowerCase()) >= 0 ||
              account
                .data()
                .email.toLowerCase()
                .indexOf(this.search.toLowerCase()) >= 0
          )
          .slice(0, 5);
      }
    },
  },
  async mounted() {
    if (this.$route.params.id) {
      this.loadingData = true;
      const hotelRef = doc(firestore, "hotels", this.$route.params.id);
      this.hotel = await getDoc(hotelRef);
      this.hotelName = this.hotel.data().name;
      document.title = `Launchpad - Crewfare - Hotel - ${this.hotelName}`;
      this.name = this.hotel.data().name || "";
      this.hotel_id = this.hotel.data().hotel_id || "";
      this.state = this.hotel.data().state || "";
      const addr = [
        this.hotel.data().addr,
        this.hotel.data().city,
        this.hotel.data().county,
        this.hotel.data().state,
        this.hotel.data().country,
        this.hotel.data().zipCode,
      ].filter((a) => a);
      if (this.hotel.data().searchAddr) {
        this.addr = this.hotel.data().searchAddr;
      } else {
        this.addr = addr.join(", ");
      }
      this.lat = this.hotel.data().lat || "";
      this.lng = this.hotel.data().lng || "";
      this.$refs.gmapAutocomplete.$refs.input.value = this.addr;
      this.rooms = this.hotel.data().rooms || "";
      this.chain = this.hotel.data().chain || "";
      this.manager = this.hotel.data().manager || [];
      const qContacts = query(
        collection(firestore, "contacts"),
        where("hotel_id", "==", this.$route.params.id)
      );
      const contacts = await getDocs(qContacts);
      this.contacts = contacts.docs.map((contact) => {
        return {
          name: contact.data().name || "",
          email: contact.data().email || "",
          role: contact.data().role || "",
          phone: contact.data().phone || "",
          contact,
        };
      });
      this.loadingData = false;
    } else {
      document.title = `Launchpad - Crewfare - Hotel - Add Hotel`;
    }
    let qUsers = query(collection(firestore, "accounts"));
    const accounts = await getDocs(qUsers);
    this.accounts = accounts.docs.filter(
      (account) =>
        account.data().type &&
        account.data().type.toLowerCase() === "group manager"
    );
  },
  methods: {
    async setPlace(place) {
      this.addr = place.formatted_address;
      this.state = place.address_components.find((component) =>
        component.types.includes("administrative_area_level_1")
      ).short_name;
      this.lat = place.geometry.location.lat();
      this.lng = place.geometry.location.lng();
    },
    addManager(account) {
      const id = this.manager.findIndex((manager) => manager.id === account.id);
      if (id > 0) {
        return;
      } else {
        this.manager.push({ id: account.id, ...account.data() });
        this.search = "";
      }
    },
    removeManager(index) {
      this.manager.splice(index, 1);
    },
    getInitials(name) {
      const nameSplit = name.split(" ");
      const firstLetter = nameSplit[0];
      let lastLetter = "";
      if (nameSplit.length > 1) {
        const lastName = nameSplit.reverse()[0];
        lastLetter = lastName[0] || "";
      }
      return `${firstLetter[0]}${lastLetter}`;
    },
    async save() {
      if (this.loading || !this.valid) return;
      this.loading = true;
      if (this.$route.params.id) {
        await setDoc(doc(firestore, "hotels", this.$route.params.id), {
          ...this.hotel.data(),
          name: this.name,
          hotel_id: this.hotel_id,
          addr: this.addr,
          lat: this.lat,
          lng: this.lng,
          chain: this.chain,
          rooms: this.rooms,
          state: this.state,
          manager: [...this.manager],
          updated_at: new Date(),
        });
        let qRfpHotel = query(
          collection(firestore, "rfp_hotel"),
          where("hotel_id", "==", this.$route.params.id)
        );
        const rfp_hotels = await getDocs(qRfpHotel);
        rfp_hotels.docs.forEach(async (rfp_hotel) => {
          await setDoc(doc(firestore, "rfp_hotel", rfp_hotel.id), {
            ...rfp_hotel.data(),
            hotel_name: this.name,
          });
        });
        await Promise.all(this.contacts.map(async (contact) => {
          if (isValidEmail(contact.email)) {
            await save({
              name: contact.name || "",
              email: contact.email || "",
              phone: contact.phone || "",
              hotelId: this.hotel.id,
            });
          }
        }));
        this.$store.commit("setToast", {
          content: `<p>Hotel Updated</p>`,
        });
      } else {
        const data = {
          name: this.name,
          hotel_id: this.hotel_id,
          addr: this.addr,
          lat: this.lat,
          lng: this.lng,
          chain: this.chain,
          rooms: this.rooms,
          state: this.state,
          manager: [...this.manager],
          created_at: new Date(),
        };
        const hotel = await addDoc(collection(firestore, "hotels"), data);
        await Promise.all(this.contacts.map(async (contact) => {
          if (isValidEmail(contact.email)) {
            await save({
              name: contact.name || "",
              email: contact.email || "",
              phone: contact.phone || "",
              hotelId:  hotel.id,
            });
          }
        }));
        this.$store.commit("setToast", {
          content: `<p>Hotel Added</p>`,
        });
      }
      this.loading = false;
      this.$router.go(-1);
    },
  },
};
</script>
