<template>
  <button
    @click="() => passedFunction?.()"
    @drop.stop.prevent="event => onDropFunction?.(event)"
    @dragover.prevent
    @dragenter.prevent
    @dragleave.prevent
    @dragstart.prevent
    @dragend.prevent
    @drag.prevent
    :class="classes[type || 'default']"
  >
    <slot></slot>
  </button>
</template>

<script lang="ts">
export default {
  props: {
    passedFunction: Function,
    onDropFunction: Function,
    type: String,
  },
  data() {
    return {
      classes: {
        default:
          'bg-crewfareBlue border border-crewfareBlue hover:bg-opacity-0 active:bg-crewfareGrey px-6 py-3 rounded-[8px] flex gap-2 text-white text-sm z-50',
      } as { [key: string]: string },
    };
  },
};
</script>
