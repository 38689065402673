<template>
  <div
    v-if="rfp"
    class="bg-gray-900 w-full p-4 flex flex-col gap-4 stage-height"
  >
    <h1 class="text-xl text-white gap-4 flex items-center">
      <router-link
        :to="{ name: 'rfpsList' }"
        class="material-symbols-rounded hover:text-crewfareGreen"
      >
        chevron_left
      </router-link>
      {{ status === "new" ? "Creating RFP" : "" }}
      {{ ["draft", "sent"].includes(status.toLowerCase()) ? "Update RFP" : "" }}
    </h1>
    <div class="flex flex-col gap-4 text-white w-full">
      <div class="flex gap-4 w-full">
        <span
          class="green-button"
          :class="isLeisure && 'green-button-active'"
          @click="toggleLeisure"
        >
          Leisure Group
        </span>
        <span
          class="green-button"
          :class="isStaff && 'green-button-active'"
          @click="toggleStaff"
        >
          Staff Group
        </span>
      </div>
      <div class="flex gap-4 w-full">
        <div class="flex flex-col gap-1 w-full">
          <label class="text-white font-bold">RFP Name *</label>
          <input
            type="text"
            v-model="name"
            class="w-full bg-gray-700 rounded px-4"
          />
        </div>
        <div class="flex flex-col gap-1 w-full">
          <div class="flex gap-4">
            <label class="text-white font-bold">Event Name</label>
            <span
              class="text-white"
              v-tippy="
                `This information will remain internal. Hotels will not see this information.`
              "
            >
              <font-awesome-icon icon="question-circle" />
            </span>
          </div>
          <input
            type="text"
            v-model="event_name"
            class="w-full bg-gray-700 rounded px-4"
          />
        </div>
        <div class="flex flex-col gap-1 w-full">
          <label class="text-white font-bold">Venue</label>
          <input
            type="text"
            v-model="venue"
            class="w-full bg-gray-700 rounded px-4"
          />
        </div>
      </div>
      <div class="flex gap-4 w-full">
        <div class="flex flex-col gap-1 w-full">
          <label class="text-white font-bold">Number of hotels</label>
          <input
            type="text"
            v-model="hotel_goal"
            class="w-full bg-gray-700 rounded px-4"
          />
        </div>
        <div class="flex flex-col gap-1 w-full">
          <label class="text-white font-bold">Number of rooms</label>
          <input
            type="text"
            v-model="room_goal"
            class="w-full bg-gray-700 rounded px-4"
          />
        </div>
        <div class="flex flex-col gap-1 w-full">
          <label class="text-white font-bold">Estimated Attendees</label>
          <input
            type="text"
            v-model="estimated_attendees"
            @blur="formatEstimatedAttendeesNumber"
            @keypress="isNumber($event)"
            class="w-full bg-gray-700 rounded px-4"
          />
        </div>
      </div>
      <div class="flex gap-4 w-full items-center">
        <div class="flex flex-col gap-1 w-full">
          <label class="text-sm font-bold">Event Address</label>
          <input
            type="text"
            id="input"
            class="w-full bg-gray-700 rounded px-4"
            v-model="search"
            ref="event_address"
            placeholder="Search Location"
          />
        </div>
      </div>
      <div class="flex flex-col gap-1">
        <label class="text-white font-bold">Image</label>
        <div class="flex gap-4">
          <div>
            <img :src="banner" class="max-h-[150px] w-auto" />
          </div>
          <div>
            <button
              :disabled="upload"
              @click="$refs['fileUpload'].click()"
              class="green-button"
            >
              <span
                class="material-symbols-rounded"
                :class="upload && 'animate-spin'"
              >
                {{ upload ? "autorenew" : "upload" }}
              </span>
              {{ upload ? `Uploading` : "Update Banner" }}
            </button>
          </div>
        </div>
        <input
          type="file"
          ref="fileUpload"
          id="fileUpload"
          @change="updateBanner"
          class="cursor-pointer fixed -left-[100vw]"
        />
      </div>
      <div class="flex gap-4 w-full">
        <div class="flex flex-col gap-1 w-full">
          <label class="text-white font-bold">Email Reply To</label>
          <input
            type="text"
            v-model="replyTo"
            class="w-full bg-gray-700 rounded px-4"
          />
        </div>
        <div class="flex flex-col gap-1 w-full">
          <label class="text-white font-bold">CC Emails</label>
          <vue3-tags-input
            :tags="ccEmails"
            placeholder="Add email"
            :validate="customValidate"
            class="w-full bg-gray-700 rounded px-4 min-h-[40px]"
            @on-tags-changed="handleChangeTag"
            @on-blur="handleBlur"
            v-model="currentCCEmail"
          />
        </div>
      </div>
      <hr class="my-4" />
      <p class="text-2xl">Event Dates</p>
      <div class="flex gap-4 w-full">
        <div class="flex flex-col gap-1 w-full">
          <label class="text-white font-bold">Check In *</label>
          <VueDatePicker
            :format="format"
            :enable-time-picker="false"
            :min-date="new Date()"
            v-model="startDate"
            @update:model-value="updateDates"
            ref="startDate"
            auto-apply
            input-class-name="w-full bg-gray-700 h-[40px] appearance-none rounded border-gray-700  text-white"
          >
            <template #action-preview="{ value }">
              {{ null }}
            </template>
          </VueDatePicker>
        </div>
        <div class="flex flex-col gap-1 w-full">
          <label class="text-white font-bold">Check Out *</label>
          <VueDatePicker
            :format="format"
            :enable-time-picker="false"
            :min-date="startDate"
            v-model="endDate"
            @update:model-value="updateDates"
            auto-apply
            ref="endDate"
            input-class-name="w-full bg-gray-700 h-[40px] appearance-none rounded border-gray-700  text-white"
          >
            <template #action-preview="{ value }">
              {{ null }}
            </template>
          </VueDatePicker>
        </div>
      </div>
      <div class="flex gap-4 w-full">
        <div class="flex flex-col gap-1 w-full">
          <label class="text-white font-bold">Response Due Date *</label>
          <VueDatePicker
            :format="format"
            :enable-time-picker="false"
            :min-date="new Date()"
            :max-date="startDate"
            auto-apply
            v-model="responseDueDate"
            ref="dueDate"
            input-class-name="w-full bg-gray-700 h-[40px] appearance-none rounded border-gray-700  text-white"
          >
            <template #action-preview="{ value }">
              {{ null }}
            </template>
            <template #action-buttons>
              <span
                class="cursor-pointer rounded border border-crewfareGreen text-crewfareGreen hover:bg-crewfareGreen hover:text-white px-4 py-2"
                @click="selectDueDate"
              >
                Select
              </span>
            </template>
          </VueDatePicker>
        </div>
        <div class="flex flex-col gap-1 w-full">
          <label class="text-white font-bold">Decision Date *</label>
          <VueDatePicker
            :format="format"
            :enable-time-picker="false"
            v-model="decisionDate"
            :max-date="startDate"
            :min-date="responseDueDate"
            ref="decisionDate"
            auto-apply
            input-class-name="w-full bg-gray-700 h-[40px] appearance-none rounded border-gray-700  text-white"
          >
            <template #action-preview="{ value }">
              {{ null }}
            </template>
            <template #action-buttons>
              <span
                class="cursor-pointer rounded border border-crewfareGreen text-crewfareGreen hover:bg-crewfareGreen hover:text-white px-4 py-2"
                @click="selectDecisionDate"
              >
                Select
              </span>
            </template>
          </VueDatePicker>
        </div>
        <div class="flex flex-col gap-1 w-full">
          <label class="text-white font-bold">Bid Expiration Date *</label>
          <VueDatePicker
            :format="format"
            :enable-time-picker="false"
            v-model="bidExpirationDate"
            ref="bidExpirationDate"
            :max-date="startDate"
            auto-apply
            input-class-name="w-full bg-gray-700 h-[40px] appearance-none rounded border-gray-700  text-white"
          >
            <template #action-preview="{ value }">
              {{ null }}
            </template>
          </VueDatePicker>
        </div>
      </div>
      <hr class="my-4" />
      <div v-if="isStaff" class="flex justify-between gap-4">
        <span
          class="green-button w-full"
          :class="hasRooms && 'green-button-active'"
          @click="toggleHasRooms"
        >
          Do you have dates and room quantities?
        </span>
        <span
          class="green-button w-full text-center"
          :class="!hasRooms && 'green-button-active'"
          @click="toggleHasRooms"
        >
          Do you only have the dates and are unsure of how many rooms you will
          need at the moment?
        </span>
      </div>
      <div class="flex w-full py-2">
        <div class="flex gap-2 items-center mt-2">
          <div class="text-sm font-bold justify-between items-center flex">
            Currency:
          </div>
          <select
            class="w-full h-[40px] print:appearance-none px-4 print:px-0 rounded bg-gray-700 text-white print:text-black"
            v-model="defaultCurrency"
          >
            <option>Select</option>
            <option>$ USD</option>
            <option>€ EUR</option>
            <option>R$ BRL</option>
            <option>C$ CAD</option>
            <option>£ GBP</option>
            <option>฿ THB</option>
          </select>
        </div>
      </div>
      <p class="text-2xl justify-between flex">Rooms</p>
      <div class="flex flex-col gap-2">
        <div class="flex gap-4 py-4">
          <div class="flex flex-col w-[220px] items-end justify-center">
            <p>Bulk Edit</p>
            <span class="text-xs text-right">
              Numbers inserted will reflect for each night except the last
            </span>
          </div>
          <div class="flex divide-x divide-gray-500 border border-gray-500">
            <div
              v-for="(room, roomIndex) in roomTypes"
              :key="roomIndex"
              class="flex flex-col divide-y divide-gray-500"
            >
              <input
                type="text"
                v-model="room.type"
                class="max-w-[180px] rounded-0 border-0 bg-gray-700 px-2"
                @input="(e) => updateDefaultRoomType(e, roomIndex)"
                placeholder="Room type"
              />
              <input
                type="text"
                class="max-w-[180px] rounded-0 border-0 bg-gray-700 px-2"
                @input="updateDefaultRoomList(roomIndex)"
                v-model="room.amount"
                placeholder="Room Amount"
                @keypress="isNumber($event)"
              />
              <div
                class="max-w-[180px] rounded-0 flex p-2 items-center justify-end"
              >
                <span
                  @click="removeRoom(roomIndex)"
                  class="cursor-pointer text-red-400 flex text-sm items-center px-4 material-symbols-rounded border rounded px-4 border-red-400 hover:bg-red-400 hover:text-white"
                >
                  delete
                </span>
              </div>
            </div>
          </div>
          <div v-if="hasRooms" class="flex items-center px-4">
            <button
              @click="addRoom()"
              class="text-crewfareGreen flex items-center px-4 material-symbols-rounded border rounded py-2 px-4 border-crewfareGreen hover:bg-crewfareGreen hover:text-gray-900"
            >
              add
            </button>
          </div>
        </div>
        <div class="flex">
          <div class="flex flex-col w-[140px]">
            <div
              class="text-center text-crewfareGreen h-[58px] flex items-center"
            >
              Room Amount
            </div>
            <div
              v-for="room in roomTypes"
              class="min-h-[42px] flex items-center justify-end pr-4"
            >
              <span
                v-if="room.type.length > 14"
                class="text-ellipsis overflow-hidden max-w-full whitespace-nowrap"
                v-tippy="room.type"
              >
                {{ room.type }}
              </span>
              <span v-else>
                {{ room.type }}
              </span>
            </div>
          </div>
          <div
            class="flex overflow-auto max-w-[70vw] divide-x divide-gray-200 border border-gray-200"
          >
            <div
              v-for="(roomItem, index) in roomList"
              :key="index"
              class="flex flex-col divide-y divide-gray-400 min-w-[180px] w-[180px] max-w-[180px]"
            >
              <div class="flex items-center flex-col justify-center gap-4">
                <span
                  class="w-full min-h-[58px] flex items-center text-center text-sm p-2 p-2"
                >
                  {{ roomItem.date }}
                </span>
              </div>
              <div class="flex flex-col">
                <div
                  v-for="(room, roomIndex) in roomItem.rooms"
                  :key="roomIndex"
                  class="flex w-full flex-col divide-y divide-gray-500 border-t border-gray-200"
                >
                  <input
                    type="number"
                    v-model="room.amount"
                    class="w-full bg-gray-700 rounded-0 px-4"
                    placeholder="Amount needed"
                    @keypress="isNumber($event)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr class="my-4" />
      <p class="text-2xl">Terms:</p>
      <div class="grid grid-cols-3 gap-4 w-full">
        <div class="flex flex-col gap-1 w-full">
          <label
            class="text-sm font-bold flex h-[44px] justify-between items-center mb-2"
          >
            Attrition *
            <button
              @click="toggleLockedFields('attrition')"
              class="text-[18px] flex items-center px-4 material-symbols-rounded border rounded py-1"
              :class="
                lockedFields.includes('attrition')
                  ? 'bg-crewfarePurple text-white border-crewfarePurple'
                  : 'text-crewfareGreen border-crewfareGreen'
              "
            >
              {{ lockedFields.includes("attrition") ? "lock" : "lock_open" }}
            </button>
          </label>
          <select
            class="w-full bg-gray-700 h-[40px] px-4 rounded"
            v-model="attrition"
          >
            <option value="">Select</option>
            <option>Courtesy Block</option>
            <option>70% Attrition</option>
            <option>80% Attrition</option>
            <option>90% Attrition</option>
            <option>100% Attrition</option>
            <option>Other</option>
          </select>
        </div>
        <div class="flex flex-col gap-1 w-full">
          <label
            class="text-sm font-bold flex h-[44px] justify-between items-center mb-2"
          >
            Cut-Off Date *
            <button
              @click="toggleLockedFields('cutoffDate')"
              class="text-[18px] flex items-center px-4 material-symbols-rounded border rounded py-1"
              :class="
                lockedFields.includes('cutoffDate')
                  ? 'bg-crewfarePurple text-white border-crewfarePurple'
                  : 'text-crewfareGreen border-crewfareGreen'
              "
            >
              {{ lockedFields.includes("cutoffDate") ? "lock" : "lock_open" }}
            </button>
          </label>
          <div class="text-base">
            Cut-Off Date
            <input
              v-model="daysToCutoff"
              @blur="updateCutOffDate"
              class="bg-gray-700 w-[50px] text-right py-1 rounded px-2 print:px-0"
              @keypress="isNumber($event)"
            />
            days prior to event.<br />
            <span class="text-sm">(Date: {{ formatedCutoffDate }})</span>
          </div>
        </div>
        <div class="flex flex-col gap-1 w-full">
          <label
            class="text-sm font-bold flex h-[44px] justify-between items-center mb-2"
          >
            Commission (%) *
            <button
              @click="toggleLockedFields('commission')"
              class="text-[18px] flex items-center px-4 material-symbols-rounded border rounded py-1"
              :class="
                lockedFields.includes('commission')
                  ? 'bg-crewfarePurple text-white border-crewfarePurple'
                  : 'text-crewfareGreen border-crewfareGreen'
              "
            >
              {{ lockedFields.includes("commission") ? "lock" : "lock_open" }}
            </button>
          </label>
          <select
            class="w-full bg-gray-700 h-[40px] px-4 rounded"
            v-model="commission"
          >
            <option value="">Select</option>
            <option>10%</option>
            <option>7%</option>
            <option>Non Commissionable</option>
          </select>
          <div
            v-if="commission === 'Non Commissionable'"
            class="flex flex-col gap-1 mt-4"
          >
            <label class="text-sm font-bold">Commission Amount:</label>
            <input
              type="text"
              v-model="commissionAmount"
              class="w-full bg-gray-700 rounded px-4"
            />
          </div>
        </div>
        <div class="flex flex-col gap-1 w-full">
          <label
            class="text-sm font-bold flex justify-between items-center mb-2"
          >
            IATA # *
            <button
              @click="toggleLockedFields('iata')"
              class="text-[18px] flex items-center px-4 material-symbols-rounded border rounded py-1"
              :class="
                lockedFields.includes('iata')
                  ? 'bg-crewfarePurple text-white border-crewfarePurple'
                  : 'text-crewfareGreen border-crewfareGreen'
              "
            >
              {{ lockedFields.includes("iata") ? "lock" : "lock_open" }}
            </button>
          </label>
          <input
            type="text"
            v-model="iata"
            class="w-full bg-gray-700 rounded px-4"
          />
        </div>
        <div class="flex flex-col gap-1 w-full">
          <label
            class="text-sm font-bold flex justify-between items-center mb-2"
          >
            Rebate *
            <button
              @click="toggleLockedFields('rebate')"
              class="text-[18px] flex items-center px-4 material-symbols-rounded border rounded py-1"
              :class="
                lockedFields.includes('rebate')
                  ? 'bg-crewfarePurple text-white border-crewfarePurple'
                  : 'text-crewfareGreen border-crewfareGreen'
              "
            >
              {{ lockedFields.includes("rebate") ? "lock" : "lock_open" }}
            </button>
          </label>
          <select
            class="w-full bg-gray-700 h-[40px] px-4 rounded"
            v-model="rebate"
          >
            <option value="">Select</option>
            <option>$10</option>
            <option>$5</option>
            <option>$4</option>
            <option>$3</option>
            <option>$2</option>
            <option>Other</option>
            <option>No Rebate</option>
          </select>
          <div class="flex gap-4">
            <label for="rebatePerNight" class="flex gap-1 w-full items-center">
              <input
                id="rebatePerNight"
                type="radio"
                value="Per Night"
                v-model="rebateType"
              />
              <span>Per Night</span>
            </label>
            <label for="rebatePerRoom" class="flex gap-1 w-full items-center">
              <input
                id="rebatePerRoom"
                type="radio"
                value="Per Room"
                v-model="rebateType"
              />
              <span>Per Room</span>
            </label>
          </div>
          <div v-if="rebate === 'Other'" class="flex flex-col gap-1 mt-4">
            <div class="text-sm font-bold">
              Other Amount:
            </div>
            <input
              type="number"
              v-model="rebateAmount"
              class="w-full bg-gray-700 rounded px-4 print:px-0"
              @keypress="isNumber($event)"
            />
          </div>
        </div>
        <div class="flex flex-col gap-1 w-full">
          <label
            class="text-sm font-bold flex justify-between items-center mb-2"
          >
            Group Rate Honored *
            <button
              @click="toggleLockedFields('groupDateHonored')"
              class="text-[18px] flex items-center px-4 material-symbols-rounded border rounded py-1"
              :class="
                lockedFields.includes('groupDateHonored')
                  ? 'bg-crewfarePurple text-white border-crewfarePurple'
                  : 'text-crewfareGreen border-crewfareGreen'
              "
            >
              {{
                lockedFields.includes("groupDateHonored") ? "lock" : "lock_open"
              }}
            </button>
          </label>
          <select
            class="w-full bg-gray-700 h-[40px] px-4 rounded"
            v-model="groupDateHonored"
          >
            <option value="">Select</option>
            <option>Yes</option>
            <option>No</option>
          </select>

          <div v-if="groupDateHonored === 'No'" class="flex gap-4 mt-2">
            <div
              class="flex gap-2 items-center bg-gray-700 justify-center rounded"
            >
              <span class="flex px-2 items-center justify-center">$</span>
              <input
                v-model="groupDateHonoredPreRate"
                type="text"
                placeholder="Pre Rate"
                class="px-4 print:px-0 pl-0 bg-transparent w-full text-right"
              />
            </div>
            <div
              class="flex gap-2 items-center bg-gray-700 justify-center rounded"
            >
              <span class="flex px-2 items-center justify-center">$</span>
              <input
                type="text"
                v-model="groupDateHonoredPostRate"
                placeholder="Post Rate"
                class="px-4 print:px-0 pl-0 bg-transparent w-full text-right"
              />
            </div>
          </div>
          <div class="flex gap-4 flex-col">
            <div class="flex flex-col gap-2">
              <label class="flex gap-1 w-full items-center">
                <input type="checkbox" v-model="groupDateHonoredPre" />
                <span>Pre Event</span>
              </label>
              <label
                v-if="groupDateHonoredPre"
                class="text-sm font-bold flex justify-between items-center mb-2"
              >
                Days Pre Event
              </label>
              <select
                v-if="groupDateHonoredPre"
                class="w-full bg-gray-700 h-[40px] px-4 rounded"
                v-model="groupDateHonoredPreDays"
              >
                <option value="">Select days</option>
                <option>Seven (7) Days</option>
                <option>Six (6) Days</option>
                <option>Five (5) Days</option>
                <option>Four (4) Days</option>
                <option>Three (3) Days</option>
                <option>Two (2) Days</option>
                <option>One (1) Day</option>
                <option>No Days</option>
              </select>
            </div>
            <div class="flex flex-col gap-2">
              <label class="flex gap-1 w-full items-center">
                <input type="checkbox" v-model="groupDateHonoredPost" />
                <span>Post Event</span>
              </label>
              <label
                v-if="groupDateHonoredPost"
                class="text-sm font-bold flex justify-between items-center mb-2"
              >
                Days Post Event
              </label>
              <select
                v-if="groupDateHonoredPost"
                class="w-full bg-gray-700 h-[40px] px-4 rounded"
                v-model="groupDateHonoredPostDays"
              >
                <option value="">Select days</option>
                <option>Seven (7) Days</option>
                <option>Six (6) Days</option>
                <option>Five (5) Days</option>
                <option>Four (4) Days</option>
                <option>Three (3) Days</option>
                <option>Two (2) Days</option>
                <option>One (1) Day</option>
                <option>No Days</option>
              </select>
            </div>
          </div>
        </div>
        <div class="flex flex-col gap-1 w-full">
          <label
            class="text-sm font-bold flex justify-between items-center mb-2"
          >
            Comp Rooms *
            <button
              @click="toggleLockedFields('compRooms')"
              class="text-[18px] flex items-center px-4 material-symbols-rounded border rounded py-1"
              :class="
                lockedFields.includes('compRooms')
                  ? 'bg-crewfarePurple text-white border-crewfarePurple'
                  : 'text-crewfareGreen border-crewfareGreen'
              "
            >
              {{ lockedFields.includes("compRooms") ? "lock" : "lock_open" }}
            </button>
          </label>
          <select
            class="w-full bg-gray-700 h-[40px] px-4 rounded"
            v-model="compRooms"
          >
            <option value="">Select</option>
            <option>1 Per 50</option>
            <option>1 Per 45</option>
            <option>1 Per 40</option>
            <option>1 Per 35</option>
            <option>1 Per 30</option>
            <option>1 Per 25</option>
            <option>1 Per 20</option>
            <option>1 Per 15</option>
            <option>1 Per 10</option>
            <option>No Comp rooms</option>
          </select>
        </div>
        <div class="flex flex-col gap-1 w-full">
          <label
            class="text-sm font-bold flex justify-between items-center mb-2"
          >
            Suite Upgrades *
            <button
              @click="toggleLockedFields('suiteUpgrades')"
              class="text-[18px] flex items-center px-4 material-symbols-rounded border rounded py-1"
              :class="
                lockedFields.includes('suiteUpgrades')
                  ? 'bg-crewfarePurple text-white border-crewfarePurple'
                  : 'text-crewfareGreen border-crewfareGreen'
              "
            >
              {{
                lockedFields.includes("suiteUpgrades") ? "lock" : "lock_open"
              }}
            </button>
          </label>
          <select
            class="w-full bg-gray-700 h-[40px] px-4 rounded"
            v-model="suiteUpgrades"
          >
            <option value="">Select</option>
            <option>Ten (10)</option>
            <option>Nine (9)</option>
            <option>Eight (8)</option>
            <option>Seven (7)</option>
            <option>Six (6)</option>
            <option>Five (5)</option>
            <option>Four (4)</option>
            <option>Three (3)</option>
            <option>Two (2)</option>
            <option>One (1)</option>
            <option>Zero (0)</option>
          </select>
        </div>
        <div class="flex flex-col gap-1 w-full">
          <label
            class="text-sm font-bold flex justify-between items-center mb-2"
          >
            Reward Points *
            <button
              @click="toggleLockedFields('rewardPoints')"
              class="text-[18px] flex items-center px-4 material-symbols-rounded border rounded py-1"
              :class="
                lockedFields.includes('rewardPoints')
                  ? 'bg-crewfarePurple text-white border-crewfarePurple'
                  : 'text-crewfareGreen border-crewfareGreen'
              "
            >
              {{ lockedFields.includes("rewardPoints") ? "lock" : "lock_open" }}
            </button>
          </label>
          <select
            class="w-full bg-gray-700 h-[40px] px-4 rounded"
            v-model="rewardPoints"
          >
            <option value="">Select</option>
            <option>Triple Rewards Points</option>
            <option>Double Rewards Points</option>
            <option>Single Rewards Points</option>
            <option>No Rewards Points</option>
          </select>
        </div>
        <div class="flex flex-col gap-1 w-full">
          <label
            class="text-sm font-bold flex justify-between items-center mb-2"
          >
            Staff Rooms *
            <button
              @click="toggleLockedFields('staffRooms')"
              class="text-[18px] flex items-center px-4 material-symbols-rounded border rounded py-1"
              :class="
                lockedFields.includes('staffRooms')
                  ? 'bg-crewfarePurple text-white border-crewfarePurple'
                  : 'text-crewfareGreen border-crewfareGreen'
              "
            >
              {{ lockedFields.includes("staffRooms") ? "lock" : "lock_open" }}
            </button>
          </label>
          <select
            class="w-full bg-gray-700 h-[40px] px-4 rounded"
            v-model="staffRooms"
          >
            <option value="">Select</option>
            <option>Ten (10)</option>
            <option>Nine (9)</option>
            <option>Eight (8)</option>
            <option>Seven (7)</option>
            <option>Six (6)</option>
            <option>Five (5)</option>
            <option>Four (4)</option>
            <option>Three (3)</option>
            <option>Two (2)</option>
            <option>One (1)</option>
            <option>Zero (0)</option>
          </select>
        </div>
        <div class="flex flex-col gap-1 w-full">
          <label
            class="text-sm font-bold flex justify-between items-center mb-2"
          >
            Staff Discount *
            <button
              @click="toggleLockedFields('staffDiscount')"
              class="text-[18px] flex items-center px-4 material-symbols-rounded border rounded py-1"
              :class="
                lockedFields.includes('staffDiscount')
                  ? 'bg-crewfarePurple text-white border-crewfarePurple'
                  : 'text-crewfareGreen border-crewfareGreen'
              "
            >
              {{
                lockedFields.includes("staffDiscount") ? "lock" : "lock_open"
              }}
            </button>
          </label>
          <select
            class="w-full bg-gray-700 h-[40px] px-4 rounded"
            v-model="staffDiscount"
          >
            <option value="">Select</option>
            <option>None</option>
            <option>10%</option>
            <option>15%</option>
            <option>20%</option>
            <option>25%</option>
            <option>30%</option>
            <option>35%</option>
            <option>40%</option>
            <option>45%</option>
            <option>50%</option>
            <option>75%</option>
            <option>Other</option>
          </select>
          <div
            v-if="staffDiscount === 'Other'"
            class="flex flex-col gap-1 mt-4"
          >
            <label
              class="text-sm font-bold flex justify-between items-center mb-2"
            >
              Staff Discount Amount:
              <button
                @click="toggleLockedFields('staffDiscountAmount')"
                class="text-[18px] flex items-center px-4 material-symbols-rounded border rounded py-1"
                :class="
                  lockedFields.includes('staffDiscountAmount')
                    ? 'bg-crewfarePurple text-white border-crewfarePurple'
                    : 'text-crewfareGreen border-crewfareGreen'
                "
              >
                {{
                  lockedFields.includes("staffDiscountAmount")
                    ? "lock"
                    : "lock_open"
                }}
              </button>
            </label>
            <input
              type="text"
              v-model="staffDiscountAmount"
              class="w-full bg-gray-700 rounded px-4"
            />
          </div>
        </div>
        <div class="flex flex-col gap-1 w-full">
          <label
            class="text-sm font-bold flex justify-between items-center mb-2"
          >
            Resort Fee *
            <button
              @click="toggleLockedFields('resortFee')"
              class="text-[18px] flex items-center px-4 material-symbols-rounded border rounded py-1"
              :class="
                lockedFields.includes('resortFee')
                  ? 'bg-crewfarePurple text-white border-crewfarePurple'
                  : 'text-crewfareGreen border-crewfareGreen'
              "
            >
              {{ lockedFields.includes("resortFee") ? "lock" : "lock_open" }}
            </button>
          </label>
          <select
            class="w-full bg-gray-700 h-[40px] px-4 rounded"
            v-model="resortFee"
          >
            <option value="">Select</option>
            <option>No Resort Fee</option>
            <option>Resort Fee</option>
          </select>
          <div
            v-if="resortFee === 'Resort Fee'"
            class="flex flex-col gap-1 mt-4"
          >
            <label
              class="text-sm font-bold flex justify-between items-center mb-2"
            >
              Resort Fee Amount:
              <button
                @click="toggleLockedFields('resortFeeAmount')"
                class="text-[18px] flex items-center px-4 material-symbols-rounded border rounded py-1"
                :class="
                  lockedFields.includes('resortFeeAmount')
                    ? 'bg-crewfarePurple text-white border-crewfarePurple'
                    : 'text-crewfareGreen border-crewfareGreen'
                "
              >
                {{
                  lockedFields.includes("resortFeeAmount")
                    ? "lock"
                    : "lock_open"
                }}
              </button>
            </label>
            <input
              type="text"
              v-model="resortFeeAmount"
              class="w-full bg-gray-700 rounded px-4"
            />
          </div>
        </div>
        <div class="flex flex-col gap-1 w-full">
          <label
            class="text-sm font-bold flex justify-between items-center mb-2"
          >
            Reservation Submission *
            <button
              @click="toggleLockedFields('reservationSubmission')"
              class="text-[18px] flex items-center px-4 material-symbols-rounded border rounded py-1"
              :class="
                lockedFields.includes('reservationSubmission')
                  ? 'bg-crewfarePurple text-white border-crewfarePurple'
                  : 'text-crewfareGreen border-crewfareGreen'
              "
            >
              {{
                lockedFields.includes("reservationSubmission")
                  ? "lock"
                  : "lock_open"
              }}
            </button>
          </label>
          <select
            class="w-full bg-gray-700 h-[40px] px-4 rounded"
            v-model="reservationSubmission"
          >
            <option value="">Select</option>
            <option>Rooming List</option>
            <option>Individual Booking</option>
          </select>
        </div>
        <div class="flex flex-col gap-1 w-full">
          <label
            class="text-sm font-bold flex justify-between items-center mb-2"
          >
            Billing *
            <button
              @click="toggleLockedFields('billing')"
              class="text-[18px] flex items-center px-4 material-symbols-rounded border rounded py-1"
              :class="
                lockedFields.includes('billing')
                  ? 'bg-crewfarePurple text-white border-crewfarePurple'
                  : 'text-crewfareGreen border-crewfareGreen'
              "
            >
              {{ lockedFields.includes("billing") ? "lock" : "lock_open" }}
            </button>
          </label>
          <select
            class="w-full bg-gray-700 h-[40px] px-4 rounded"
            v-model="billing"
          >
            <option value="">Select</option>
            <option>Room + tax to Master Account</option>
            <option>Room + tax to Individuals</option>
            <option>Other</option>
          </select>
          <div v-if="billing === 'Other'" class="flex flex-col gap-1 mt-4">
            <label
              class="text-sm font-bold flex justify-between items-center mb-2"
            >
              Other:
              <button
                @click="toggleLockedFields('billingsOther')"
                class="text-[18px] flex items-center px-4 material-symbols-rounded border rounded py-1"
                :class="
                  lockedFields.includes('billingsOther')
                    ? 'bg-crewfarePurple text-white border-crewfarePurple'
                    : 'text-crewfareGreen border-crewfareGreen'
                "
              >
                {{
                  lockedFields.includes("billingsOther") ? "lock" : "lock_open"
                }}
              </button>
            </label>
            <input
              type="text"
              v-model="billingsOther"
              class="w-full bg-gray-700 rounded px-4"
            />
          </div>
        </div>
        <div class="flex flex-col gap-1 w-full">
          <label
            class="text-sm font-bold flex justify-between items-center mb-2"
          >
            Incidentals *
            <button
              @click="toggleLockedFields('incidentals')"
              class="text-[18px] flex items-center px-4 material-symbols-rounded border rounded py-1"
              :class="
                lockedFields.includes('incidentals')
                  ? 'bg-crewfarePurple text-white border-crewfarePurple'
                  : 'text-crewfareGreen border-crewfareGreen'
              "
            >
              {{ lockedFields.includes("incidentals") ? "lock" : "lock_open" }}
            </button>
          </label>
          <select
            id="incidentals"
            class="w-full px-4 bg-gray-700 h-[40px] rounded"
            v-model="incidentals"
          >
            <option value="">Select</option>
            <option>Incidentals to Individuals</option>
            <option>Incidentals to Master Account</option>
            <option>Other</option>
          </select>
          <div v-if="incidentals === 'Other'" class="flex flex-col gap-1 mt-4">
            <label
              class="text-sm font-bold flex justify-between items-center mb-2"
            >
              Other:
              <button
                @click="toggleLockedFields('incidentalsOther')"
                class="text-[18px] flex items-center px-4 material-symbols-rounded border rounded py-1"
                :class="
                  lockedFields.includes('incidentalsOther')
                    ? 'bg-crewfarePurple text-white border-crewfarePurple'
                    : 'text-crewfareGreen border-crewfareGreen'
                "
              >
                {{
                  lockedFields.includes("incidentalsOther")
                    ? "lock"
                    : "lock_open"
                }}
              </button>
            </label>
            <input
              type="text"
              v-model="incidentalsOther"
              class="w-full bg-gray-700 rounded px-4"
            />
          </div>
        </div>
        <div class="flex flex-col gap-1 w-full">
          <label
            class="text-sm font-bold flex justify-between items-center mb-2"
          >
            Wi-fi
            <button
              @click="toggleLockedFields('wifi')"
              class="text-[18px] flex items-center px-4 material-symbols-rounded border rounded py-1"
              :class="
                lockedFields.includes('wifi')
                  ? 'bg-crewfarePurple text-white border-crewfarePurple'
                  : 'text-crewfareGreen border-crewfareGreen'
              "
            >
              {{ lockedFields.includes("wifi") ? "lock" : "lock_open" }}
            </button>
          </label>
          <select
            id="wifi"
            class="w-full px-4 bg-gray-700 h-[40px] rounded"
            v-model="wifi"
          >
            <option value="">Select</option>
            <option>Included</option>
            <option>Not included</option>
          </select>
        </div>
        <div class="flex flex-col gap-1 w-full">
          <label
            class="text-sm font-bold flex justify-between items-center mb-2"
          >
            Parking
            <button
              @click="toggleLockedFields('parking')"
              class="text-[18px] flex items-center px-4 material-symbols-rounded border rounded py-1"
              :class="
                lockedFields.includes('parking')
                  ? 'bg-crewfarePurple text-white border-crewfarePurple'
                  : 'text-crewfareGreen border-crewfareGreen'
              "
            >
              {{ lockedFields.includes("parking") ? "lock" : "lock_open" }}
            </button>
          </label>
          <select
            id="parking"
            class="w-full px-4 bg-gray-700 h-[40px] rounded"
            v-model="parking"
          >
            <option value="">Select</option>
            <option>Included</option>
            <option>Not included</option>
          </select>
          <div
            v-if="parking === 'Not included'"
            class="flex flex-col gap-1 mt-4"
          >
            <label
              class="text-sm font-bold flex justify-between items-center mb-2"
            >
              Price:
              <button
                @click="toggleLockedFields('parkingOther')"
                class="text-[18px] flex items-center px-4 material-symbols-rounded border rounded py-1"
                :class="
                  lockedFields.includes('parkingOther')
                    ? 'bg-crewfarePurple text-white border-crewfarePurple'
                    : 'text-crewfareGreen border-crewfareGreen'
                "
              >
                {{
                  lockedFields.includes("parkingOther") ? "lock" : "lock_open"
                }}
              </button>
            </label>
            <input
              type="text"
              v-model="parkingOther"
              class="w-full bg-gray-700 rounded px-4"
            />
          </div>
        </div>
        <div class="flex flex-col gap-1 w-full">
          <label
            class="text-sm font-bold flex justify-between items-center mb-2"
          >
            Breakfast
            <button
              @click="toggleLockedFields('breakfast')"
              class="text-[18px] flex items-center px-4 material-symbols-rounded border rounded py-1"
              :class="
                lockedFields.includes('breakfast')
                  ? 'bg-crewfarePurple text-white border-crewfarePurple'
                  : 'text-crewfareGreen border-crewfareGreen'
              "
            >
              {{ lockedFields.includes("breakfast") ? "lock" : "lock_open" }}
            </button>
          </label>
          <select
            id="breakfast"
            class="w-full px-4 bg-gray-700 h-[40px] rounded"
            v-model="breakfast"
          >
            <option value="">Select</option>
            <option>Included</option>
            <option>Not included</option>
          </select>
          <div
            v-if="breakfast === 'Not included'"
            class="flex flex-col gap-1 mt-4"
          >
            <label
              class="text-sm font-bold flex justify-between items-center mb-2"
            >
              Price:
              <button
                @click="toggleLockedFields('breakfastOther')"
                class="text-[18px] flex items-center px-4 material-symbols-rounded border rounded py-1"
                :class="
                  lockedFields.includes('breakfastOther')
                    ? 'bg-crewfarePurple text-white border-crewfarePurple'
                    : 'text-crewfareGreen border-crewfareGreen'
                "
              >
                {{
                  lockedFields.includes("breakfastOther") ? "lock" : "lock_open"
                }}
              </button>
            </label>
            <input
              type="text"
              v-model="breakfastOther"
              class="w-full bg-gray-700 rounded px-4"
            />
          </div>
        </div>
        <div class="flex flex-col gap-1 w-full">
          <label
            class="text-sm font-bold flex justify-between items-center mb-2"
          >
            Other fees*
            <button
              @click="toggleLockedFields('otherFees')"
              class="text-[18px] flex items-center px-4 material-symbols-rounded border rounded py-1"
              :class="
                lockedFields.includes('otherFees')
                  ? 'bg-crewfarePurple text-white border-crewfarePurple'
                  : 'text-crewfareGreen border-crewfareGreen'
              "
            >
              {{ lockedFields.includes("otherFees") ? "lock" : "lock_open" }}
            </button>
          </label>
          <select
            id="otherFees"
            class="w-full px-4 bg-gray-700 h-[40px] rounded"
            v-model="otherFees"
          >
            <option value="">Select</option>
            <option>Yes</option>
            <option>No</option>
          </select>
          <div v-if="otherFees === 'Yes'" class="flex flex-col gap-1 mt-4">
            <label
              class="text-sm font-bold flex justify-between items-center mb-2"
            >
              Value:
              <button
                @click="toggleLockedFields('otherFeesValue')"
                class="text-[18px] flex items-center px-4 material-symbols-rounded border rounded py-1"
                :class="
                  lockedFields.includes('otherFeesValue')
                    ? 'bg-crewfarePurple text-white border-crewfarePurple'
                    : 'text-crewfareGreen border-crewfareGreen'
                "
              >
                {{
                  lockedFields.includes("otherFeesValue") ? "lock" : "lock_open"
                }}
              </button>
            </label>
            <input
              type="text"
              v-model="otherFeesValue"
              class="w-full bg-gray-700 rounded px-4"
            />
          </div>
        </div>
      </div>
      <div class="flex flex-col gap-1">
        <label class="text-white font-bold">Additional Terms (Optional)</label>
        <div
          ref="termsInput"
          contenteditable="true"
          class="w-full bg-gray-700 rounded px-4 py-2"
        ></div>
      </div>
      <div class="flex gap-4 mt-4 justify-between">
        <router-link :to="{ name: 'rfpsList' }" class="cancel-button">
          Cancel
        </router-link>
        <div class="flex gap-4">
          <span
            @click="confirmSetDefaults"
            class="blue-button"
            :class="!defaultsChanged && 'opacity-50'"
          >
            Restore Defaults
          </span>
          <button
            :disabled="!valid || loadingSave"
            @click="save({ redirect: false })"
            class="yellow-button"
          >
            <span
              v-if="loadingSave"
              class="material-symbols-rounded animate-spin flex items-center"
            >
              autorenew
            </span>
            <span v-else> Save </span>
          </button>
          <button
            :disabled="!valid || loading"
            @click="save({ redirect: true })"
            class="green-button"
          >
            <span
              v-if="loading"
              class="material-symbols-rounded animate-spin flex items-center"
            >
              autorenew
            </span>
            <span v-else> Save and Select Hotels </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Loader } from "@googlemaps/js-api-loader";
import router from "@/router";
import { v4 as uuidv4 } from "uuid";
import { getRFP, setStatusHistory } from "@/utils/rfp.jsx";
import { firestore } from "@/utils/firebase";
import {
  setDoc,
  doc,
  getDoc,
  query,
  getDocs,
  collection,
  where,
} from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { sendNotificationAdmin } from "@/utils/notifications.jsx";
import Vue3TagsInput from "vue3-tags-input";
import moment from "moment";
import { getDateObjFromFirebaseDate } from "@/utils/dateUtils";
import _ from "lodash";
import { isValidEmail } from "@crewfare/utils";
import { rfpsApi } from "@/utils/apis/rfpsApi";

export default {
  components: {
    Vue3TagsInput,
  },
  data() {
    return {
      currentCCEmail: "",
      replyTo: "",
      venue: "",
      event_name: "",
      search: "",
      searchError: "",
      location: {},
      hotel_goal: "",
      room_goal: "",
      roomTypes: [],
      storageRef: "",
      upload: false,
      rfp: null,
      banner: "",
      loading: false,
      loadingSave: false,
      name: null,
      radius: 30,
      defaultCurrency: "$ USD",
      responseDueDate: null,
      decisionDate: null,
      ccEmails: [],
      attrition: null,
      cutoffDate: null,
      findingHotels: null,
      cutoffDateDays: null,
      commission: null,
      iata: null,
      rebate: null,
      rebateAmount: null,
      groupDateHonored: null,
      compRooms: null,
      staffRooms: null,
      suiteUpgrades: null,
      staffDiscount: null,
      rewardPoints: null,
      resortFee: null,
      reservationSubmission: null,
      billing: null,
      incidentals: null,
      incidentalsOther: null,
      billingsOther: null,
      resortFeeAmount: null,
      staffDiscountAmount: null,
      rebateType: null,
      estimated_attendees: null,
      groupDateHonoredPreRate: null,
      groupDateHonoredPostRate: null,
      groupDateHonoredPre: null,
      groupDateHonoredPreDays: "",
      groupDateHonoredPost: null,
      groupDateHonoredPostDays: "",
      commissionAmount: null,
      startDate: null,
      endDate: null,
      bidExpirationDate: null,
      wifi: null,
      parking: null,
      parkingOther: null,
      breakfast: null,
      breakfastOther: null,
      roomList: [],
      lockedFields: ["iata"],
      otherFees: null,
      otherFeesAmount: null,
      isLeisure: false,
      isStaff: false,
      hasRooms: true,
      hotelsFound: 0,
      defaults: {
        otherFees: "No",
        iata: "10614251",
        attrition: "Courtesy Block",
        commission: "10%",
        rebate: "$5",
        rebateAmount: "",
        groupDateHonored: "Yes",
        groupDateHonoredPre: true,
        groupDateHonoredPreDays: "Four (4) Days",
        groupDateHonoredPost: true,
        groupDateHonoredPostDays: "Four (4) Days",
        compRooms: "1 Per 20",
        suiteUpgrades: "Two (2)",
        staffRooms: "Two (2)",
        staffDiscount: "25%",
        rewardPoints: "Triple Rewards Points",
        reservationSubmission: "Rooming List",
        resortFee: "No Resort Fee",
        billing: "Room + tax to Master Account",
        incidentals: "Incidentals to Individuals",
        rebateType: "Per Night",
        groupRateTypePre: true,
        groupRateTypePost: true,
      },
    };
  },
  computed: {
    formatedCutoffDate() {
      return moment(this.cutoffDate).format("MM-DD-YYYY");
    },
    account() {
      return this.$store.state.account;
    },
    cutOffDateDefault() {
      if (!this.startDate) return;
      const date = moment(this.startDate);
      const today = moment();
      const differenceInMs = date.diff(today);
      const duration = moment.duration(differenceInMs);
      if (duration.asDays() > 21) {
        return date.subtract(21, "days").toDate();
      } else {
        return today.toDate();
      }
    },
    responseDateDefault() {
      if (!this.cutOffDateDefault) {
        return;
      }
      return this.cutOffDateDefault;
    },
    decisionDateDefault() {
      if (!this.responseDateDefault) {
        return;
      }
      return this.cutOffDateDefault;
    },
    valid() {
      return this.name;
    },
    status() {
      return this.rfp?.status;
    },
    defaultsChanged() {
      return (
        this.attrition !== this.defaults.attrition ||
        this.commission !== this.defaults.commission ||
        this.rebate !== this.defaults.rebate ||
        this.rebateAmount !== this.defaults.rebateAmount ||
        this.groupDateHonored !== this.defaults.groupDateHonored ||
        this.compRooms !== this.defaults.compRooms ||
        this.suiteUpgrades !== this.defaults.suiteUpgrades ||
        this.staffRooms !== this.defaults.staffRooms ||
        this.staffDiscount !== this.defaults.staffDiscount ||
        this.rewardPoints !== this.defaults.rewardPoints ||
        this.reservationSubmission !== this.defaults.reservationSubmission ||
        this.resortFee !== this.defaults.resortFee ||
        this.billing !== this.defaults.billing ||
        this.rebateType !== this.defaults.rebateType ||
        this.groupRateTypePre !== this.defaults.groupRateTypePre ||
        this.groupRateTypePost !== this.defaults.groupRateTypePost ||
        this.incidentals !== this.defaults.incidentals ||
        this.wifi !== this.defaults.wifi ||
        this.breakfast !== this.defaults.breakfast ||
        this.parking !== this.defaults.parking
      );
    },
  },
  watch: {
    search() {
      if (!google.maps.places) return;
      var autocomplete = new google.maps.places.Autocomplete(
        document.getElementById("input")
      );

      autocomplete.addListener("place_changed", async () => {
        const address = autocomplete.getPlace();
        this.location = {
          lat: address.geometry.location.lat(),
          lng: address.geometry.location.lng(),
        };
        this.search = this.$refs.event_address.value;
      });
    },
  },
  async mounted() {
    const apiKey = "AIzaSyCwDSzKEVg4LGxPAqvcyJTwngTj7OPFJ18";
    const loader = new Loader({
      apiKey,
    });

    loader.load().then(async () => {
      await google.maps.importLibrary("places");
    });
    this.rfp = await getRFP(this.$route.params.id);
    this.startDate = getDateObjFromFirebaseDate(this.rfp.startDate);
    this.endDate = getDateObjFromFirebaseDate(this.rfp.endDate);
    this.cutoffDate = !this.rfp.cutoffDate
      ? this.cutOffDateDefault
      : getDateObjFromFirebaseDate(this.rfp.cutoffDate);
    this.responseDueDate = !this.rfp.responseDueDate
      ? this.responseDateDefault
      : getDateObjFromFirebaseDate(this.rfp.responseDueDate);
    this.decisionDate = !this.rfp.decisionDate
      ? this.decisionDateDefault
      : getDateObjFromFirebaseDate(this.rfp.decisionDate);
    this.bidExpirationDate = getDateObjFromFirebaseDate(this.rfp.bidExpirationDate || this.rfp.startDate);
    this.hasRooms = this.rfp.hasRooms || true;
    this.roomList = this.rfp.roomList
      ? this.rfp.roomList
      : this.setRoomsList();
    this.name = this.rfp.name;
    this.ccEmails = this.rfp.ccEmails || [];
    this.attrition = this.rfp.attrition;
    this.commission = this.rfp.commission;
    this.iata = this.rfp.iata;
    this.rebate = this.rfp.rebate;
    this.rebateAmount = this.rfp.rebateAmount;
    this.defaultCurrency = this.rfp.defaultCurrency || this.defaultCurrency;
    this.groupDateHonored = this.rfp.groupDateHonored;
    this.compRooms = this.rfp.compRooms;
    this.staffRooms = this.rfp.staffRooms;
    this.suiteUpgrades = this.rfp.suiteUpgrades;
    this.staffDiscount = this.rfp.staffDiscount;
    this.rewardPoints = this.rfp.rewardPoints;
    this.resortFee = this.rfp.resortFee;
    this.reservationSubmission = this.rfp.reservationSubmission;
    this.billing = this.rfp.billing;
    this.incidentals = this.rfp.incidentals;
    this.incidentalsOther = this.rfp.incidentalsOther;
    this.billingsOther = this.rfp.billingsOther;
    this.resortFeeAmount = this.rfp.resortFeeAmount;
    this.staffDiscountAmount = this.rfp.staffDiscountAmount;
    this.rebateType = this.rfp.rebateType;
    this.groupRateTypePre = this.rfp.groupRateTypePre;
    this.groupRateTypePost = this.rfp.groupRateTypePost;
    this.commissionAmount = this.rfp.commissionAmount;
    this.lockedFields = this.rfp.lockedFields || ["iata"];
    this.wifi = this.rfp.wifi;
    this.parking = this.rfp.parking;
    this.parkingOther = this.rfp.parkingOther;
    this.breakfast = this.rfp.breakfast;
    this.breakfastOther = this.rfp.breakfastOther;
    this.venue = this.rfp.venue;
    this.event_name = this.rfp.event_name;
    this.hotel_goal = this.rfp.hotel_goal;
    this.room_goal = this.rfp.room_goal;
    this.search = this.rfp.search;
    this.replyTo = this.rfp.replyTo;
    this.otherFees = this.rfp.otherFees;
    this.otherFeesAmount = this.rfp.otherFeesAmount;
    this.groupDateHonoredPre = this.rfp.groupDateHonoredPre;
    this.groupDateHonoredPreDays = this.rfp.groupDateHonoredPreDays;
    this.groupDateHonoredPost = this.rfp.groupDateHonoredPost;
    this.groupDateHonoredPostDays = this.rfp.groupDateHonoredPostDays;
    this.groupDateHonoredPreRate = this.rfp.groupDateHonoredPreRate;
    this.groupDateHonoredPostRate = this.rfp.groupDateHonoredPostRate;
    this.isLeisure = this.rfp.isLeisure;
    this.isStaff = this.rfp.isStaff;
    this.estimated_attendees = this.rfp.estimated_attendees;

    const date = moment(this.startDate);
    const cutoff = moment(this.cutoffDate);
    const diff = date.diff(cutoff);
    const duration = moment.duration(diff);
    this.daysToCutoff = duration.asDays();
    this.setRoomTypes();
    if (this.rfp.banner) {
      this.banner = this.rfp.banner;
    }
    if (this.rfp.status === "new") {
      this.setDefaults();
    }
    setTimeout(() => {
      if (this.$refs.termsInput)
        this.$refs.termsInput.innerHTML = this.rfp.terms || "";
    }, 1000);
    document.title = `Launchpad - Crewfare - RFP - Form`;
  },
  methods: {
    formatEstimatedAttendeesNumber() {
      const estimated_attendees = parseInt(this.estimated_attendees);
      if (estimated_attendees >= 1000)
        this.estimated_attendees = `${estimated_attendees / 1000}K`;
    },
    updateCutOffDate() {
      const date = moment(this.startDate);
      const dateCutOff = date.subtract(this.daysToCutoff, "days");
      this.cutoffDate = dateCutOff.toDate();
    },
    setPlace() {
      if (!google.maps.places) return;
      var autocomplete = new google.maps.places.Autocomplete(
        document.getElementById("input")
      );

      autocomplete.addListener("place_changed", async () => {
        const address = autocomplete.getPlace();
        this.location = {
          lat: address.geometry.location.lat(),
          lng: address.geometry.location.lng(),
        };
        this.search = this.$refs.event_address.value;
      });
    },
    updateDates() {
      console.log("Changing room")
      this.bidExpirationDate = this.startDate;
      this.roomList = this.setRoomsList();
      this.updateCutOffDate();
    },
    toggleHasRooms() {
      this.hasRooms = !this.hasRooms;
      this.roomList = this.setRoomsList();
      this.setRoomTypes();
    },
    toggleLeisure() {
      this.isLeisure = true;
      this.isStaff = false;
      this.hasRooms = true;
    },
    toggleStaff() {
      this.isStaff = true;
      this.isLeisure = false;
    },
    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    toggleLockedFields(field) {
      if (this.lockedFields.includes(field)) {
        this.lockedFields.splice(this.lockedFields.indexOf(field), 1);
      } else {
        this.lockedFields.push(field);
      }
    },
    updateBanner() {
      var file = document.getElementById("fileUpload").files[0];
      if (file) {
        const storage = getStorage();
        const uuid = uuidv4();
        const name = file.name;
        const ext = name.split(".").reverse()[0];
        this.storageRef = ref(storage, `${uuid}.${ext}`);
        this.upload = true;
        uploadBytes(this.storageRef, file).then((snapshot) => {
          getDownloadURL(this.storageRef).then((url) => {
            (this.banner = url), (this.upload = false);
          });
        });
      }
    },
    updateDefaultRoomList(roomTypeIndex) {
      this.roomList.map((day, index) => {
        day.rooms.map((room, roomIndex) => {
          if (roomIndex === roomTypeIndex) {
            room.amount = this.roomTypes[roomTypeIndex].amount;
          }
        });
      });
    },
    updateDefaultRoomType(event, indexType) {
      this.roomList.map((day) => {
        day.rooms.map((room, indexRoom) => {
          if (indexType === indexRoom) {
            room.type = event.target.value;
          }
        });
      });
    },
    setRoomsList() {
      const currentRoomList = _.cloneDeep(this.roomList);
      console.log("Current room list", currentRoomList)
      const roomList = [];
      const startDate = moment(this.startDate);
      const endDate = moment(this.endDate);
      const differenceInMs = endDate.diff(startDate);
      const duration = moment.duration(differenceInMs);
      let diffDays = Math.ceil(duration.asDays());
      if (diffDays < 1) {
        this.endDate = startDate.add(3, "days");
        diffDays = 3;
      }
      const range = [...Array(diffDays).keys()];
      const dates = [];
      range.forEach((day) => {
        dates.push(
          moment(this.startDate).add(day, "days").format("dddd MMMM, DD, YYYY")
        );
      });
      dates.forEach((item) => {
        const currentDateItem = currentRoomList.find(
          (rooms) => rooms.date === item
        );
        roomList.push({
          date: item,
          rooms: this.roomTypes.map((room, _index) => {
            const currentRoom = currentDateItem?.rooms.length>_index ? currentDateItem.rooms[_index] : null;
            return {
              type: room.type,
              amount: currentRoom?.amount || this.roomTypes[_index]?.amount || "",
            };
          }),
        });
      });
      return roomList;
    },
    setRoomTypes() {
      const types = [
        ...new Set(
          ...this.roomList.map(
            (dates) => new Set(dates.rooms.map((room) => room.type))
          )
        ),
      ];
      this.roomTypes = this.hasRooms
        ? types.map((type) => {
            return { type, amount: "" };
          })
        : [
            {
              type: "Room",
              amount: "",
            },
          ];
    },
    removeRoom(roomIndex) {
      this.roomTypes.splice(roomIndex, 1);
      this.roomList.forEach((date) => date.rooms.splice(roomIndex, 1));
    },
    addRoom() {
      this.roomTypes.push({
        type: "New Room",
        amount: "",
      });
      this.roomList.forEach((date) =>
        date.rooms.push({
          type: "New Room",
          amount: "",
        })
      );
    },
    format(date) {
      return moment(date).format("MM-DD-YYYY");
    },
    confirmSetDefaults() {
      if (!confirm("Are you sure?")) return;
      this.setDefaults();
    },
    setDefaults() {
      this.iata = this.defaults.iata;
      this.attrition = this.defaults.attrition;
      this.commission = this.defaults.commission;
      this.rebate = this.defaults.rebate;
      this.rebateAmount = this.defaults.rebateAmount;
      this.groupDateHonored = this.defaults.groupDateHonored;
      this.compRooms = this.defaults.compRooms;
      this.suiteUpgrades = this.defaults.suiteUpgrades;
      this.staffRooms = this.defaults.staffRooms;
      this.staffDiscount = this.defaults.staffDiscount;
      this.rewardPoints = this.defaults.rewardPoints;
      this.reservationSubmission = this.defaults.reservationSubmission;
      this.resortFee = this.defaults.resortFee;
      this.billing = this.defaults.billing;
      this.incidentals = this.defaults.incidentals;
      this.rebateType = this.defaults.rebateType;
      this.groupRateTypePre = this.defaults.groupRateTypePre;
      this.groupRateTypePost = this.defaults.groupRateTypePost;
      this.replyTo = this.account.email;
      this.wifi = this.defaults.wifi || "";
      this.parking = this.defaults.parking || "";
      this.parkingOther = this.defaults.parkingOther || "";
      this.breakfast = this.defaults.breakfast || "";
      this.breakfastOther = this.defaults.breakfastOther || "";
      this.otherFees = this.defaults.otherFees || "";
      this.groupDateHonoredPre = this.defaults.groupDateHonoredPre;
      this.groupDateHonoredPreDays =
        this.defaults.groupDateHonoredPreDays || "";
      this.groupDateHonoredPost = this.defaults.groupDateHonoredPost || "";
      this.groupDateHonoredPostDays =
        this.defaults.groupDateHonoredPostDays || "";
    },
    customValidate(value) {
      const regex = new RegExp(/^[\w-\.+]+@([\w-]+\.)+[\w-]{2,}$/);
      return regex.test(value);
    },
    handleChangeTag(tags) {
      this.ccEmails = [...new Set(tags.filter((tag) => isValidEmail(tag)))];
    },
    handleBlur(e) {
      const email = e.target.value;
      const tags = [...this.ccEmails]
      if (isValidEmail(email)) {
        tags.push(email);
        this.currentCCEmail = ""
      }
      this.handleChangeTag(tags);
    },
    async save({ redirect }) {
      const terms = this.$refs.termsInput.innerHTML;
      if (redirect) this.loading = true;
      else this.loadingSave = true;
      const roomListHistoric = [
        {
          date: moment().format("MM-DD-YYYY"),
          roomList: this.roomList || [],
        },
      ];
      const relatedEmails = [
        this.account.email,
        ...(this.ccEmails || []),
      ];
      let status = "draft";
      if (this.rfp.status.toLowerCase() === "sent") status = "sent";
      const data = {
        ...this.rfp,
        replyTo: this.replyTo || "",
        name: this.name || "",
        decisionDate: new Date(this.decisionDate) || "",
        bidExpirationDate: new Date(this.bidExpirationDate) || "",
        responseDueDate: new Date(this.responseDueDate) || "",
        ccEmails: this.ccEmails || [],
        relatedEmails,
        attrition: this.attrition || "",
        cutoffDate: new Date(this.cutoffDate) || "",
        defaultCurrency: this.defaultCurrency || "",
        commission: this.commission || "",
        iata: this.iata || "",
        rebate: this.rebate || "",
        rebateAmount: this.rebateAmount || "",
        estimated_attendees: this.estimated_attendees || "",
        groupDateHonored: this.groupDateHonored || "",
        groupDateHonoredPre: this.groupDateHonoredPre || "",
        groupDateHonoredPreDays: this.groupDateHonoredPreDays || "",
        groupDateHonoredPost: this.groupDateHonoredPost || "",
        groupDateHonoredPostDays: this.groupDateHonoredPostDays || "",
        groupDateHonoredPreRate: this.groupDateHonoredPreRate || "",
        groupDateHonoredPostRate: this.groupDateHonoredPostRate || "",
        compRooms: this.compRooms || "",
        staffRooms: this.staffRooms || "",
        suiteUpgrades: this.suiteUpgrades || "",
        staffDiscount: this.staffDiscount || "",
        rewardPoints: this.rewardPoints || "",
        resortFee: this.resortFee || "",
        reservationSubmission: this.reservationSubmission || "",
        billing: this.billing || "",
        incidentals: this.incidentals || "",
        incidentalsOther: this.incidentalsOther || "",
        billingsOther: this.billingsOther || "",
        resortFeeAmount: this.resortFeeAmount || "",
        staffDiscountAmount: this.staffDiscountAmount || "",
        rebateType: this.rebateType || "",
        groupRateTypePre: this.groupRateTypePre || "",
        groupRateTypePost: this.groupRateTypePost || "",
        commissionAmount: this.commissionAmount || "",
        startDate: new Date(this.startDate) || "",
        endDate: new Date(this.endDate) || "",
        wifi: this.wifi || "",
        breakfast: this.breakfast || "",
        breakfastOther: this.breakfastOther || "",
        parking: this.parking || "",
        parkingOther: this.parkingOther || "",
        roomList: this.roomList || [],
        roomListHistoric,
        banner: this.banner || "",
        lockedFields: this.lockedFields || [],
        otherFees: this.otherFees || "",
        otherFeesAmount: this.otherFeesAmount || "",
        venue: this.venue || "",
        event_name: this.event_name || "",
        hotel_goal: this.hotel_goal || "",
        room_goal: this.room_goal || "",
        search: this.search || "",
        location: this.location || "",
        isLeisure: this.isLeisure || "",
        isStaff: this.isStaff || "",
        hasRooms: this.hasRooms || false,
        status,
        terms,
        updated_at: new Date(),
      };
      const updated = await rfpsApi.update(this.$route.params.id, data);
      if(updated.error){
        alert(updated.message)
        this.loadingSave = false;
        return;
      }
      sendNotificationAdmin({
        type: "status_change",
        content: `RFP ${this.name} has been updated by ${
          this.account.name
        }`,
        to: { name: "rfpsForm", params: { id: this.$route.params.id } },
      });
        if (redirect) {
          this.$store.commit("setSelectedRFP", {
            id: this.$route.params.id,
            name: this.name,
          });
          router.push({
            name: "searchView",
            query: {
              search: this.search,
              startDate: moment(this.startDate).format("MM-DD-YYYY"),
              endDate: moment(this.endDate).format("MM-DD-YYYY"),
              lat: this.location.lat,
              lng: this.location.lng
            },
          });
          this.loading = false;
        } else {
          this.$store.commit("setToast", {
            content: `<p>RFP draft saved!</p>`,
          });
          router.push({
          name: "rfpsHotels",
          params: { id: this.$route.params.id },
        });
          this.loadingSave = false;
        }
    },
  },
};
</script>
