<template>
  <div class="flex flex-col gap-4">
    <div class="text-white text-base font-medium">{{ title }}</div>
    <input
      type="text"
      :placeholder="placeholder"
      class="box-border min-w-[255px] rounded-[8px] px-5 py-4 text-sm hover:border-crewfarePurple appearance-none border-2 focus:outline-none font-normal text-white"
      :class="currentClass"
      :disabled="isDisabled || isLocked"
    />
  </div>
  <p v-if="isError" class="text-crewfareRed text-xs mt-2">{{ errorMessage }}</p>
</template>

<script lang="ts">
export default {
  props: {
    title: String,
    placeholder: String,
    isError: Boolean,
    isLocked: Boolean,
    isDisabled: Boolean,
    errorMessage: String,
  },
  data() {
    return {
      classes: {
        default:
          'bg-crewfareGrey border-transparent',
        error:
          'bg-crewfareRed bg-opacity-[4%] border-crewfareRed',
        locked: 'bg-crewfareGrey hover:border-transparent border-transparent',
        disabled:
          'bg-crewfareGrey text-crewfareGrey cursor-not-allowed border-crewfareGreyLighter hover:border-crewfareGreyLighter',
      } as { [key: string]: string },
    };
  },
  computed: {
    currentClass() {
      switch (true) {
        case this.isLocked:
          return this.classes.locked;
        case this.isError:
          return this.classes.error;
        case this.isDisabled:
          return this.classes.disabled;
        default:
          return this.classes.default;
      }
    },
  },
};
</script>
