import { BaseApi, getToken } from './apiFactory';

class ProposalsAPI extends BaseApi {
  async send(proposalIds: string[], rfpId: string) {
    const token = await getToken();
    return this.axios({
      url: `${this.apiUrl}/send`,
      method: 'POST',
      data: { proposalIds, rfpId },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
  async remind(proposalIds: string[], rfpId: string) {
    const token = await getToken();
    return this.axios({
      url: `${this.apiUrl}/remind`,
      method: 'POST',
      data: { proposalIds, rfpId },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
}

export const proposalsApi = new ProposalsAPI({ name: 'proposals' });
