<template>
  <div
    v-if="rfp && hotel && account"
    class="bg-gray-800 w-full pb-4 flex flex-col gap-4 stage-height print:text-black"
  >
    <WelcomeDialog
      v-if="isHotel && showMessage && !hasBeenSigned"
      :hotel_name="rfp.data().hotel_name"
      @hide_dialog="showMessage = false"
    />
    <ContractFields
      v-if="editHotelFields || hasEmptyField || !contract_content"
      :isHotel="editHotelFields || isHotel"
      :editHotelFields="editHotelFields"
      :rfp="rfp"
      :account="account"
      @save="saveUpdateContract"
      :closeDialog="()=>{console.log('closing diaglor');this.editHotelFields = false}"
      @get_data="getData"
    />
    <div class="print:hidden sticky z-10 top-0 p-4 bg-gray-900">
      <div class="text-xl text-white gap-4 flex items-center justify-between">
        <div class="flex gap-2">
          RFP - {{ rfp.data().name }} - {{ rfp.data().hotel_name }} - Contract
        </div>
        <div class="flex gap-4 text-sm">
          <a
            :href="contractDownloadLink"
            target="_blank"
            class="text-sm whitespace-nowrap"
          >
            <span v-if="downloadingContract" class="flex items-center gap-2">
              <span class="material-symbols-rounded animate-spin text-[14px]">
                autorenew
              </span>
              Downloading agreement
            </span>
            <span v-else class="underline">Download Agreement</span>
          </a>
        </div>
      </div>
      <div class="py-2 flex text-white justify-between items-center">
        <div class="flex gap-4">
          <span class="flex text-xs">
            <span
              class="border rounded-l bg-crewfareGreen text-gray-900 border-crewfareGreen px-2 py-1"
            >
              Use Crewfare Contract
            </span>
            <span
              @click="toggleContractMethod"
              class="border cursor-pointer text-crewfareGreen rounded-r border-crewfareGreen px-2 py-1"
            >
              Use External Contract
            </span>
          </span>
          <ResumeDialog :rfp="rfp" />
        </div>
        <ContractInternalHistoryDialog
          v-if="contract_history"
          :contract_history="contract_history"
          @get_data="getData"
        />
      </div>
      <div
        v-if="isCrewfare"
        class="py-2 flex text-white justify-between items-center"
      >
        <div class="flex gap-4">
          <router-link
            :to="{
              name: 'rfpView',
              params: { rfp: rfp.id },
              query: { admin: true },
            }"
            class="border text-xs rounded text-crewfareGreen hover:bg-crewfareGreen hover:text-gray-900 border-crewfareGreen px-2 py-1"
          >
            Update RFP
          </router-link>
          <button
            @click="() => restartSigningProcess()"
            class="border text-xs rounded text-crewfareGreen hover:bg-crewfareGreen hover:text-gray-900 border-crewfareGreen px-2 py-1"
          >
            Restart Signing Process
          </button>
          <button
            v-if="isCrewfare && !(hasEmptyField || !contract_content)"
            @click="() => this.editHotelFields = true"
            class="border text-xs rounded text-crewfareGreen hover:bg-crewfareGreen hover:text-gray-900 border-crewfareGreen px-2 py-1"
          >
            Edit Hotel Fields
          </button>
        </div>
      </div>
    </div>
    <ContractHints :proposal="rfp.data()" />
    <div>
      <div class="mx-4">
        <Editor
          v-if="rfp && hotel && contract_content"
          :rfp="rfp"
          :hotel="hotel"
          :signed="signed"
          :contract_content="contract_content"
          :has_comments="has_comments"
          :has_changes="has_changes"
          @initChanges="init_changes"
          @hasChanges="toggleHasChanges"
          @acceptChange="acceptChange"
          @rejectChange="rejectChange"
          @saveComment="saveComments"
        />
      </div>
      <div v-if="has_changes || has_comments">
        <div class="flex gap-4 mt-4 px-4 justify-end print:hidden">
          <div class="flex gap-4 items-center">
            <button
              v-if="!needToResolve"
              @click="editContract"
              class="green-button disabled:bg-gray-200 disabled:text-black disabled:border-gray-200 gap-2"
            >
              <span v-if="updatingContract" class="flex items-center">
                <span class="material-symbols-rounded animate-spin">
                  autorenew
                </span>
              </span>
              Save and Send for Review
            </button>
            <div class="w-full text-white px-4" v-else>Please resolve {{ isHotel ? "Crewfare's": "hotel's" }} changes first</div>
          </div>
        </div>
      </div>
      <div v-else-if="Object.keys(myChanges).length > 0 || rfp.data().status === 'Update Bid Terms'">
        <div v-if="(!allSelfChangesDeclined)&&(isHotel === rfp.data().isHotel)">
          <p class="text-center text-white mt-6">Waiting for approval.</p>
        </div>
        <div v-else class="flex gap-4 mt-4 px-4 justify-end print:hidden">
          <div
            class="flex gap-4 items-center"
          >
            <button
              v-if="!needToResolve"
              @click="acceptContractTerms"
              class="green-button disabled:bg-gray-200 disabled:text-black disabled:border-gray-200"
            >
              <span v-if="signingContract" class="flex items-center">
                <span class="material-symbols-rounded animate-spin">
                  autorenew
                </span>
              </span>
              Accept New Terms
            </button>
            <div class="w-full text-white px-4" v-else>Please resolve {{ isHotel ? "Crewfare's": "hotel's" }} changes first</div>
          </div>
        </div>
      </div>
      <div v-else class="w-full text-white px-4" ref="document_signature">
        <table
          cellpadding="0"
          cellspacing="0"
          style="margin-top: 16px"
          class="w-full"
        >
          <tr>
            <td width="400px" valign="top">
              <div class="flex gap-4 flex-col max-w-[400px]">
                <div style="margin-top: 12px">GROUP: CREWFARE LLC</div>
                <div class="flex flex-col gap-1">
                  <span>Signature:</span>
                  <div
                    v-if="
                      !regenerating &&
                      !isFullySigned &&
                      (hide_signature || isCrewfare)
                    "
                    class="flex flex-col gap-2"
                  >
                    <div
                      class="bg-white rounded"
                      ref="signaturePadCrewfareHolder"
                    >
                      <VueSignaturePad
                        width="400px"
                        height="160px"
                        ref="signaturePadCrewfare"
                      />
                    </div>
                    <div v-if="!isSigned" class="flex justify-between">
                      <button
                        @click="undo('crewfare')"
                        class="red-button-small"
                      >
                        Clear
                      </button>
                      <button
                        @click="save('crewfare')"
                        class="green-button-small gap-2"
                      >
                        <span v-if="savingSign" class="flex items-center">
                          <span
                            class="text-[10px] material-symbols-rounded animate-spin"
                          >
                            autorenew
                          </span>
                        </span>
                        Save
                      </button>
                    </div>
                  </div>
                  <img
                    v-if="isFullySigned || regenerating || isHotel"
                    :src="crewfare_signature"
                    class="rounded bg-white"
                    style="width: 350px"
                  />
                </div>
                <div
                  style="margin-top: 12px"
                  class="flex items-center gap-2 flex-col"
                >
                  <span class="text-left w-full">Name:</span>
                  <span class="w-full">
                    <input
                      v-if="isCrewfare && !hasBeenSigned"
                      type="text"
                      v-model="crewfare_name"
                      @input="validateInputs"
                      @blur="validateInputs"
                      class="w-full bg-gray-600 py-2 px-4 rounded"
                      :class="
                        crewfare_name.length < 1
                          ? 'border-yellow-200'
                          : 'border-gray-600'
                      "
                    />
                    <span v-else class="text-yellow-200 print:text-yellow-900"
                      >&nbsp;{{ crewfare_name }}</span
                    >
                  </span>
                </div>
                <div
                  style="margin-top: 12px"
                  class="flex items-center gap-2 flex-col"
                >
                  <span class="text-left w-full">Title:</span>
                  <span class="w-full">
                    <input
                      v-if="isCrewfare && !hasBeenSigned"
                      type="text"
                      v-model="crewfare_title"
                      @input="validateInputs"
                      @blur="validateInputs"
                      class="w-full bg-gray-600 py-2 px-4 rounded"
                      :class="
                        crewfare_title.length < 1
                          ? 'border-yellow-200'
                          : 'border-gray-600'
                      "
                    />
                    <span v-else class="text-yellow-200 print:text-yellow-900"
                      >&nbsp;{{ crewfare_title }}</span
                    >
                  </span>
                </div>
                <div
                  style="margin-top: 12px"
                  class="flex items-center gap-2 flex-col"
                >
                  <span class="text-left w-full">Date:</span>
                  <span class="w-full">
                    <input
                      v-if="isCrewfare && !hasBeenSigned"
                      type="text"
                      v-model="crewfare_date"
                      class="w-full bg-gray-600 py-2 px-4 rounded"
                      @change="validateDate(crewfare_date)"
                      :class="
                        crewfare_date.length < 1
                          ? 'border-yellow-200'
                          : 'border-gray-600'
                      "
                    />
                    <span v-else class="text-yellow-200 print:text-yellow-900"
                      >&nbsp;{{ crewfare_date }}</span
                    >
                  </span>
                </div>
              </div>
            </td>
            <td width="40px"></td>
            <td width="400px" valign="top">
              <div class="flex gap-4 flex-col max-w-[400px]">
                <div style="margin-top: 12px">
                  HOTEL: {{ hotel.data().name }}
                </div>
                <div class="flex flex-col gap-1">
                  <span>Signature:</span>
                  <div
                    v-if="
                      !regenerating &&
                      !isFullySigned &&
                      (hide_signature || isHotel)
                    "
                    class="flex flex-col gap-2"
                  >
                    <div
                      class="bg-white rounded max-w-[400px]"
                      ref="signaturePadHotelHolder"
                    >
                      <VueSignaturePad
                        width="400px"
                        height="160px"
                        ref="signaturePadHotel"
                      />
                    </div>
                    <div v-if="!isSigned" class="flex justify-between">
                      <button @click="undo('hotel')" class="red-button-small">
                        Clear
                      </button>
                      <button
                        @click="save('hotel')"
                        class="green-button-small gap-2"
                      >
                        <span v-if="savingSign" class="flex items-center">
                          <span
                            class="text-[10px] material-symbols-rounded animate-spin"
                          >
                            autorenew
                          </span>
                        </span>
                        Save
                      </button>
                    </div>
                  </div>
                  <div
                    v-if="regenerating || isFullySigned || isCrewfare"
                    class="bg-white"
                  >
                    <img
                      :src="hotel_signature"
                      class="rounded bg-white"
                      style="width: 350px"
                    />
                  </div>
                </div>
                <div
                  style="margin-top: 12px"
                  class="flex items-center gap-2 flex-col"
                >
                  <span class="text-left w-full">Name:</span>
                  <span class="w-full">
                    <input
                      v-if="isHotel && !hasBeenSigned"
                      type="text"
                      v-model="hotel_person_name"
                      @input="validateInputs"
                      @blur="validateInputs"
                      class="w-full bg-gray-600 py-2 px-4 rounded"
                      :class="
                        hotel_person_name.length < 1
                          ? 'border-yellow-200'
                          : 'border-gray-600'
                      "
                    />
                    <span v-else class="text-yellow-200 print:text-yellow-900"
                      >&nbsp;{{ hotel_person_name }}</span
                    >
                  </span>
                </div>
                <div
                  style="margin-top: 12px"
                  class="flex items-center gap-2 flex-col"
                >
                  <span class="text-left w-full">Title:</span>
                  <span class="w-full">
                    <input
                      v-if="isHotel && !hasBeenSigned"
                      type="text"
                      v-model="hotel_title"
                      @input="validateInputs"
                      @blur="validateInputs"
                      class="w-full bg-gray-600 py-2 px-4 rounded"
                      :class="
                        hotel_title.length < 1
                          ? 'border-yellow-200'
                          : 'border-gray-600'
                      "
                    />
                    <span v-else class="text-yellow-200 print:text-yellow-900"
                      >&nbsp;{{ hotel_title }}</span
                    >
                  </span>
                </div>
                <div
                  style="margin-top: 12px"
                  class="flex items-center gap-2 flex-col"
                >
                  <span class="text-left w-full">Date:</span>
                  <span class="w-full">
                    <input
                      v-if="isHotel && !hasBeenSigned"
                      type="text"
                      v-model="hotel_date"
                      class="w-full bg-gray-600 py-2 px-4 rounded"
                      :class="
                        hotel_date.length < 1
                          ? 'border-yellow-200'
                          : 'border-gray-600'
                      "
                    />
                    <span v-else class="text-yellow-200 print:text-yellow-900"
                      >&nbsp;{{ hotel_date }}</span
                    >
                  </span>
                </div>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div
      v-if="!isFullySigned && rfp.data().status !== 'Update Bid Terms'"
      class="flex gap-4 mt-4 px-4 justify-between print:hidden"
    >
      <div style="margin-top: 12px">
        <a
          :href="contractDownloadLink"
          target="_blank"
          class="green-button"
        >
          Download Agreement Here
        </a>
      </div>
      <div
        v-if="!has_comments && (isHotel || isCrewfare) && !isSigned"
        class="flex gap-4 items-center"
      >
        <div v-if="error" class="text-red-200">
          Please verity your signature and if there are missing fields on your
          contract
        </div>
        <button
          v-if="!has_changes"
          @click="accept"
          class="green-button disabled:bg-gray-200 disabled:text-black disabled:border-gray-200"
        >
          <span v-if="signingContract" class="flex items-center">
            <span class="material-symbols-rounded animate-spin">
              autorenew
            </span>
          </span>
          Sign Contract
        </button>
      </div>
    </div>
    <div
      v-if="rfp.data().status.toLowerCase() === 'signed by both sides'"
      class="text-center mt-4 flex items-center gap-4 flex-col justify-center"
    >
      <div class="text-white">This contract is fully signed.</div>
      <div v-if="isCrewfare" class="flex gap-4">
        <button @click="getContract" class="green-button gap-2">
          <span
            v-if="regenerating"
            class="material-symbols-rounded animate-spin"
          >
            autorenew
          </span>
          Update contract
        </button>
        <button @click="regenerateContract" class="green-button gap-2">
          <span
            v-if="regenerating"
            class="material-symbols-rounded animate-spin"
          >
            autorenew
          </span>
          Send to execute
        </button>
      </div>
    </div>
    <div class="hidden">
      <div
        ref="rfpResume"
        class="overflow-auto max-h-[400px] flex flex-col gap-2 text-base"
      >
        <strong class="px-4 text-xl mb-2">{{ rfp.data().name }}</strong>
        <strong class="px-4" style="margin-top: 12px"
          >Property name: {{ rfp.data().hotel_name }}</strong
        >
        <strong class="px-4" style="margin-top: 12px"
          >Name of client (sourcing): Crewfare</strong
        >
        <strong class="px-4" style="margin-top: 12px"
          >Date downloaded: {{ todayFormated }}</strong
        >
        <div v-if="rfp.data().attrition" class="px-4" style="margin-top: 12px">
          Currency:
          <strong>{{ rfp.data().defaultCurrency || "$ USD" }}</strong>
        </div>
        <div
          v-for="(roomItem, index) in rfp.data().roomList"
          :key="index"
          class="flex gap-2 px-4"
          style="margin-top: 12px"
        >
          <div class="flex flex-col gap-2">
            <div>
              Date: <strong>{{ roomItem.date }}</strong>
            </div>
            <div v-for="(room, roomIndex) in roomItem.rooms">
              {{ room.amount }} {{ room.type }} -
              {{ rfp.data().defaultCurrency || "$ USD" }} ${{ room.value }}
            </div>
          </div>
        </div>
        <strong class="px-4" style="margin-top: 12px">Occupancy</strong>
        <div class="px-4" v-for="room in rfp.data().roomTypes">
          {{ room.name }} - Occuppancy: {{ room.occupancy }} - Value:
          {{ rfp.data().defaultCurrency || "$ USD" }} ${{ room.additional }}
        </div>
        <div class="grid grid-cols-3 gap-4 p-4 w-full">
          <div v-if="rfp.data().attrition" style="margin-top: 12px">
            Attrition:
            <strong>{{ rfp.data().attrition }}</strong>
          </div>
          <div v-if="rfp.data().cutoffDate" style="margin-top: 12px">
            Cut-Off Date:
            <strong>{{ cutoffDateFormatted }}</strong>
          </div>
          <div
            v-if="rfp.data().commission"
            class="flex flex-col gap-2"
            style="margin-top: 12px"
          >
            <span>
              Commission(%):
              <strong>{{ rfp.data().commission }}</strong>
            </span>
            <div v-if="rfp.data().commission === 'Non Commissionable'">
              Commission Amount:
              <strong>{{ rfp.data().commissionAmount }}</strong>
            </div>
          </div>
          <div v-if="rfp.data().iata" style="margin-top: 12px">
            IATA #:
            <strong>{{ rfp.data().iata }}</strong>
          </div>
          <div
            v-if="rfp.data().rebate"
            class="flex flex-col gap-2"
            style="margin-top: 12px"
          >
            <span>
              Rebate: <strong>{{ rfp.data().rebate }}</strong>
            </span>
            <span>
              Rebate Type: <strong>{{ rfp.data().rebateType }}</strong>
            </span>
          </div>
          <div
            v-if="rfp.data().groupDateHonored"
            class="flex flex-col gap-2"
            style="margin-top: 12px"
          >
            <div>
              Group Rate Honored:
              <strong>{{ rfp.data().groupDateHonored }}</strong>
            </div>
            <div v-if="rfp.data().groupDateHonored === 'No'">
              <div>
                <span class="flex px-2 items-center justify-center">$</span>
                <span>
                  {{ rfp.data().groupDateHonoredPreRate }}
                </span>
              </div>
              <div>
                <span class="flex px-2 items-center justify-center">$</span>
                <span>
                  {{ rfp.data().groupDateHonoredPostRate }}
                </span>
              </div>
            </div>
            <div>
              <div v-if="rfp.data().groupDateHonoredPre">
                Days Pre Event:
                <strong>{{ rfp.data().groupDateHonoredPreDays }}</strong>
              </div>
              <div v-if="rfp.data().groupDateHonoredPost">
                Days Post Event
                <strong>{{ rfp.data().groupDateHonoredPostDays }}</strong>
              </div>
            </div>
          </div>
          <div v-if="rfp.data().compRooms" style="margin-top: 12px">
            Comp Rooms:
            <strong>{{ rfp.data().compRooms }}</strong>
          </div>
          <div v-if="rfp.data().suiteUpgrades" style="margin-top: 12px">
            Suite Upgrades:
            <strong>{{ rfp.data().suiteUpgrades }}</strong>
          </div>
          <div v-if="rfp.data().rewardPoints" style="margin-top: 12px">
            Reward Points:
            <strong>{{ rfp.data().rewardPoints }}</strong>
          </div>
          <div v-if="rfp.data().staffDiscount" style="margin-top: 12px">
            Staff Rooms:
            <strong>{{ rfp.data().staffRooms }}</strong>
          </div>
          <div
            v-if="rfp.data().staffDiscount"
            class="flex flex-col gap-2"
            style="margin-top: 12px"
          >
            <div>
              Staff Discount:
              <strong>{{ rfp.data().staffDiscount }}</strong>
            </div>
            <div v-if="rfp.data().staffDiscount === 'Other'">
              Staff Discount Amount:
              <strong>{{ rfp.data().staffDiscountAmount }}</strong>
            </div>
          </div>
          <div
            v-if="rfp.data().resortFee"
            class="flex flex-col gap-2"
            style="margin-top: 12px"
          >
            <div>
              Resort Fee:
              <strong>{{ rfp.data().resortFee }}</strong>
            </div>
            <div v-if="rfp.data().resortFee === 'Resort Fee'">
              Resort Fee Amount:
              <strong>{{ rfp.data().resortFeeAmount }}</strong>
            </div>
          </div>
          <div
            v-if="rfp.data().billing"
            class="flex flex-col gap-2"
            style="margin-top: 12px"
          >
            <div>
              Reservation Submission:
              <strong>{{ rfp.data().reservationSubmission }}</strong>
            </div>
          </div>
          <div
            v-if="rfp.data().billing"
            class="flex flex-col gap-2"
            style="margin-top: 12px"
          >
            <div>
              Billing:
              <strong>{{ rfp.data().billing }}</strong>
            </div>
            <div v-if="rfp.data().billing === 'Other'">
              Other: <strong>{{ rfp.data().billingsOther }}</strong>
            </div>
          </div>
          <div
            v-if="rfp.data().incidentals"
            class="flex flex-col gap-2"
            style="margin-top: 12px"
          >
            <div>
              Incidentals:
              <strong>{{ rfp.data().incidentals }}</strong>
            </div>
            <div v-if="rfp.data().incidentals === 'Other'">
              Other: <strong>{{ rfp.data().incidentalsOther }}</strong>
            </div>
          </div>
          <div
            v-if="rfp.data().wifi"
            class="flex flex-col gap-2 w-full"
            style="margin-top: 12px"
          >
            <div>
              Wi-Fi:
              <strong>{{ rfp.data().parking }}</strong>
            </div>
            <div v-if="rfp.data().wifi === 'Not included'">
              Price:
              <strong>
                {{ rfp.data().wifi_price }}
              </strong>
            </div>
          </div>
          <div
            v-if="rfp.data().parking"
            class="flex flex-col gap-2 w-full"
            style="margin-top: 12px"
          >
            <div>
              Parking:
              <strong>{{ rfp.data().parking }}</strong>
            </div>
            <div v-if="rfp.data().parking === 'Not included'">
              Price:
              <strong>
                {{ rfp.data().parkingOther }}
              </strong>
            </div>
          </div>
          <div
            v-if="rfp.data().breakfast"
            class="flex flex-col gap-2 w-full"
            style="margin-top: 12px"
          >
            <div>
              Breakfast:
              <strong>{{ rfp.data().breakfast }}</strong>
            </div>
            <div v-if="rfp.data().breakfast === 'Not included'">
              Price:
              <strong>
                {{ rfp.data().breakfastOther }}
              </strong>
            </div>
          </div>
          <div
            v-if="rfp.data().otherFees"
            class="flex flex-col gap-2 w-full"
            style="margin-top: 12px"
          >
            <div>
              Other Fees:
              <strong>{{ rfp.data().otherFees }}</strong>
            </div>
            <div v-if="rfp.data().otherFees === 'Yes'">
              Price:
              <strong>
                {{ rfp.data().otherFeesAmount }}
              </strong>
            </div>
          </div>
        </div>
        <div v-if="rfp.data().terms" class="p-4 flex flex-col gap-1">
          <div>Additional Terms (Optional)</div>
          <div v-html="rfp.data().terms"></div>
        </div>
      </div>
    </div>
    <div
      v-if="signErrorModal"
      class="z-40 fixed top-0 right-0 left-0 bottom-0 flex items-center justify-center"
    >
      <div
        class="fixed top-0 right-0 left-0 bottom-0 flex items-center justify-center z-[100] bg-black/50"
      ></div>
      <div
        class="bg-gray-900 z-[101] rounded-lg shadow-md py-4 text-white flex flex-col gap-4 justify-center p-4 items-center"
      >
        <p class="text-sm text-center px-4">
          Please click save under signature in order to send contract
        </p>
        <div class="flex px-4">
          <button @click="signErrorModal = false" class="cancel-button">
            Dismiss
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { jasonSignature } from "@/assets/jason-signature.jsx";
import { ResumeDialog } from "@/components/rfp";
import ContractBoston from "@/components/rfp/contract/ContractBoston.vue";
import ContractDagny from "@/components/rfp/contract/ContractDagny.vue";
import ContractFields from "@/components/rfp/contract/ContractFields.vue";
import ContractHints from "@/components/rfp/contract/ContractHints.vue";
import ContractInternalHistoryDialog from "@/components/rfp/contract/ContractInternalHistoryDialog.vue";
import ContractMarriot from "@/components/rfp/contract/ContractMarriot.vue";
import DefaultContract from "@/components/rfp/contract/DefaultContract.vue";
import Editor from "@/components/rfp/contract/Editor.vue";
import RequestEditDialog from "@/components/rfp/contract/RequestEditDialog.vue";
import WelcomeDialog from "@/components/rfp/contract/WelcomeDialog.vue";
import { contractExecutedEmailContent, contractExecutedSubject } from "@/emails/contract-executed";
import { contractExecutedInternalEmailContent, contractExecutedInternalSubject } from "@/emails/contract-executed-internal";
import { contractSignedHotelEmailContent, contractSignedHotelSubject } from "@/emails/contract-signed-hotel";
import { contractSignedInternalEmailContent, contractSignedInternalSubject } from "@/emails/contract-signed-internal";
import router from "@/router";
import { getMomentFromFirebaseDate } from "@/utils/dateUtils";
import { processVariables, sendEmail } from "@/utils/emails.jsx";
import { firestore } from "@/utils/firebase";
import {
  sendNotificationAdmin,
  sendNotificationHotels,
} from "@/utils/notifications.jsx";
import { setStatusHistory } from "@/utils/rfp.jsx";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import moment from "moment";

import { formatPhone } from "@/utils/formatter";
import { getNsoByChain, sendNsoCommunication } from "@/utils/nso";
import { unique } from "@crewfare/utils";

export default {
  components: {
    ResumeDialog,
    WelcomeDialog,
    DefaultContract,
    ContractDagny,
    ContractFields,
    ContractHints,
    Editor,
    ContractBoston,
    RequestEditDialog,
    ContractMarriot,
    ContractInternalHistoryDialog,
    Editor,
  },
  data() {
    return {
      generatingPDF: false,
      editor: null,
      has_comments: false,
      signErrorModal: false,
      apiUrl:
        import.meta.env.VITE_API_URL ||
        "https://dev-api-launchpad.crewfare.com",
      appUrl: import.meta.env.VITE_URL || "https://launchpad.crewfare.com",
      hide_signature: false,
      regenerating: false,
      init: null,
      activeComment: null,
      contractMethod: null,
      showMessage: true,
      savingFields: false,
      savingSign: false,
      error: false,
      crewfareHasSigned: false,
      signingContract: false,
      hotelHasSigned: false,
      downloadingContract: false,
      contract_content: null,
      contract_content_edit: null,
      hotel_signature: "",
      hotel_name: "",
      hotel_person_name: "",
      hotel_address: "",
      hotel_email: "",
      hotel_telephone: "",
      hotel_title: "",
      hotel_date: "",
      crewfare_date: "",
      crewfare_signature: "",
      crewfare_name: "Jason van Esso",
      crewfare_title: "Partner",
      crewfare_members: "",
      hotel_taxes: "",
      hotel_other_fees: "",
      loadingAccept: true,
      message: null,
      rfp: null,
      parentRfp: null,
      hotel: null,
      contract_history: null,
      signed: [],
      editor_enabled: false,
      contract: "",
      commentButton: false,
      selection: "",
      loadingSigned: false,
      terms: "",
      signatureCrewfare: null,
      signatureHotel: null,
      has_comments: false,
      toggleRequestEdit: false,
      updatingContract: false,
      changesInitiated: false,
      originalChanges: {},
      acceptedChanges: {},
      hoteldeclinedChanges: {},
      crewfareDeclinedChanges: {},
      hotelChanges: {},
      crewfareChanges: {},
      newChanges: {},
      editHotelFields: false
    };
  },
  computed: {
    relatedEmails(){
      return [...new Set(
        [...(this.rfp?.data()?.relatedEmails||[]) ,
        ...(this.parentRfp?.data()?.ccEmails || [])]
      )]
    },
    contractDownloadLink(){
      let nest_api_url = import.meta.env.VITE_NEST_API_URL
      return `${nest_api_url}/contract/${this.rfp.id}/download`
    },
    contractDownloadAsJSONLink(){
      let nest_api_url = import.meta.env.VITE_NEST_API_URL
      return `${nest_api_url}/contract/${this.rfp.id}/download?inJSON=true`
    },
    myChanges(){
      return this.isHotel ? this.hotelChanges : this.crewfareChanges
    },
    othersDeclinedChanges(){
      return this.isHotel ? this.crewfareDeclinedChanges : this.hoteldeclinedChanges
    },
    myDeclinedChanges(){
      return this.isHotel ? this.hoteldeclinedChanges : this.crewfareDeclinedChanges
    },
    othersChanges(){
      return this.isHotel ? this.crewfareChanges : this.hotelChanges
    },
    needToResolve(){
      return Object.keys(this.othersChanges).length !== Object.keys(this.acceptedChanges).length + Object.keys(this.othersDeclinedChanges).length
    },
    allSelfChangesDeclined(){
      return Object.keys(this.myChanges).length === Object.keys(this.myDeclinedChanges).length
    },
    has_changes(){
      console.log("has_change status",{
        original: this.originalChanges,
        hotelChanges: this.hotelChanges,
        crewfareChanges: this.crewfareChanges,
        newChanges: this.newChanges,
        acceptedChange: this.acceptedChanges,
        myChanges: this.myChanges,
        myDeclined: this.myDeclinedChanges,
        othersDeclined: this.othersDeclinedChanges
      })
      let onlySomeOfOwnChangesDeclined = Object.keys(this.myDeclinedChanges).length > 0 && !this.allSelfChangesDeclined
      //Send for review in case there's new change, or declined some other's change or self existing change is there
      return (Object.keys(this.newChanges).length + Object.keys(this.othersDeclinedChanges).length) || onlySomeOfOwnChangesDeclined
    },
    todayFormated() {
      return moment().format("ddd MMM. DD, YYYY");
    },
    cutoffDays() {
      const date = getMomentFromFirebaseDate(this.rfp.data().startDate);
      const cutoff = getMomentFromFirebaseDate(this.rfp.data().cutoffDate);
      const diff = date.diff(cutoff);
      const duration = moment.duration(diff);
      return Math.floor(duration.asDays());
    },
    types() {
      if (!this.rfp) return;
      if (this.rfp.data().roomTypes) {
        const types = this.rfp
          .data()
          .roomTypes.filter((type) => type.name.length > 0);
        return types;
      }
      const types = [];
      this.rfp.data().roomList.forEach((day) => {
        day.rooms.forEach((room) => {
          if (room.type.length > 0) types.push(room.type);
        });
      });
      return [...new Set(types)];
    },
    dates() {
      const days = [];
      this.rfp.data().roomList.forEach((day) => {
        days.push(day.date);
      });
      return [...new Set(days)];
    },
    rooms() {
      const roomsList = [];
      this.rfp.data().roomList.forEach((day) => {
        roomsList.push({
          day: day.date,
          ...day.rooms,
        });
      });
      return roomsList;
    },
    endDateFormated() {
      if (!this.rfp.data().endDate) return "";
      const date = this.rfp.data().endDate;
      return getMomentFromFirebaseDate(date).format("MM-DD-YYYY");
    },
    startDateFormated() {
      if (!this.rfp.data().startDate) return "";
      const date = this.rfp.data().startDate;
      return getMomentFromFirebaseDate(date).format("MM-DD-YYYY");
    },
    effectiveDateFormated() {
      if (!this.rfp.data().effective_date) return "";
      const date = this.rfp.data().effective_date;
      return getMomentFromFirebaseDate(date).format("MM-DD-YYYY");
    },
    cutoffDateFormated() {
      if (!this.rfp.data().cutoffDate) return "";
      const date = this.rfp.data().cutoffDate;
      return getMomentFromFirebaseDate(date).format("MM-DD-YYYY");
    },
    cutoffDateFormatted() {
      if (!this.rfp.data().cutoffDate) return "";
      return getMomentFromFirebaseDate(this.rfp.data().cutoffDate).format("D-M-Y");
    },
    isReadyToSign() {
      if (this.edit_mode) return false;
      if (this.isHotel) return this.hotelReadyToSign;
      if (this.isCrewfare) return this.crewfareReadyToSign;
      return false;
    },
    Date() {
      return moment().format("MM-DD-YYYY");
    },
    crewfareReadyToSign() {
      return (
        this.crewfare_signature?.length > 4 &&
        this.crewfare_date.length > 0 &&
        this.crewfare_name.length > 0 &&
        this.crewfare_title.length > 0
      );
    },
    hotelReadyToSign() {
      return (
        this.hotel_signature?.length > 4 &&
        this.hotel_date.length > 0 &&
        this.hotel_person_name.length > 0 &&
        this.hotel_title.length > 0
      );
    },
    hasBeenSigned() {
      if (this.isHotel) return this.signed.includes("hotel");
      if (this.isCrewfare) return this.signed.includes("crewfare");
      return false;
    },
    cutoffDays() {
      const date = getMomentFromFirebaseDate(this.rfp.data().startDate);
      const cutoff = getMomentFromFirebaseDate(this.rfp.data().cutoffDate);
      const diff = date.diff(cutoff);
      const duration = moment.duration(diff);
      return Math.floor(duration.asDays());
    },
    isSigned() {
      console.log("Hotel sign status", this.isHotel, this.rfp.data().status.toLowerCase())
      if (this.isHotel) {
        return this.rfp.data().status.toLowerCase() === "signed by the hotel";
      } else {
        return this.rfp.data().status.toLowerCase() === "signed by crewfare";
      }
    },
    isFullySigned() {
      return this.rfp.data().signed?.length === 2;
    },
    isHotel() {
      if (!this.account) return;
      return ["contact"].includes(this.account.type.toLowerCase());
    },
    isCrewfare() {
      if (!this.account) return;
      return ["internal", "admin"].includes(
        this.account.type.toLowerCase()
      );
    },
    hasEmptyField() {
      if (!this.rfp) return false;
      if (this.isHotel) {
        return (
          !this.rfp.data().hotel_person_name ||
          !this.rfp.data().hotel_email &&
          !this.rfp.data().hotel_title &&
          !this.rfp.data().hotel_taxes &&
          !this.rfp.data().hotel_check_in_age &&
          !this.rfp.data().hotel_check_in_time &&
          !this.rfp.data().hotel_check_out_time &&
          !this.rfp.data().hotel_local_occupancy_tax &&
          !this.rfp.data().hotel_state_occupancy_tax &&
          !this.rfp.data().hotel_city_tax &&
          !this.rfp.data().hotel_convention_tax &&
          !this.rfp.data().hotel_vat_tax &&
          !this.rfp.data().hotel_sat_tax &&
          !this.rfp.data().hotel_municipal_accomodation_tax &&
          !this.rfp.data().hotel_hst_tax &&
          !this.rfp.data().hotel_sustaintability_fee
        );
      }
      if (this.isCrewfare) {
        return !this.rfp.data().crewfare_members;
      }
      return false;
    },
    contractIsFilled() {
      return (
        this.rfp.data().hotel_person_name &&
        this.rfp.data().hotel_address &&
        this.rfp.data().hotel_email &&
        this.rfp.data().hotel_title &&
        this.rfp.data().hotel_taxes &&
        this.rfp.data().hotel_check_in_age &&
        this.rfp.data().hotel_check_in_time &&
        this.rfp.data().hotel_check_out_time &&
        this.rfp.data().hotel_local_occupancy_tax &&
        this.rfp.data().hotel_city_tax &&
        this.rfp.data().hotel_convention_tax &&
        this.rfp.data().hotel_vat_tax &&
        this.rfp.data().hotel_sat_tax &&
        this.rfp.data().hotel_municipal_accomodation_tax &&
        this.rfp.data().hotel_hst_tax &&
        this.rfp.data().hotel_sustaintability_fee &&
        this.rfp.data().crewfare_members
      );
    },
    isValid() {
      if (this.isHotel) {
        return (
          this.hotel_signature.length > 3 &&
          this.hotel_name.length > 0 &&
          this.hotel_person_name.length > 0 &&
          this.hotel_address.length > 0 &&
          this.hotel_email.length > 0 &&
          this.hotel_telephone.length > 0 &&
          this.hotel_title.length > 0 &&
          this.hotel_date.length > 0 &&
          this.rfp.data().hotel_check_in_age &&
          this.rfp.data().hotel_check_in_time &&
          this.rfp.data().hotel_check_out_time &&
          this.rfp.data().hotel_local_occupancy_tax &&
          this.rfp.data().hotel_city_tax &&
          this.rfp.data().hotel_convention_tax &&
          this.rfp.data().hotel_vat_tax &&
          this.rfp.data().hotel_sat_tax &&
          this.rfp.data().hotel_municipal_accomodation_tax &&
          this.rfp.data().hotel_hst_tax &&
          this.rfp.data().hotel_sustaintability_fee &&
          this.hotel_taxes.length > 0
        );
      }
      if (this.isCrewfare) {
        return (
          this.crewfare_signature.length > 3 &&
          this.crewfare_date.length > 0 &&
          this.crewfare_name.length > 0 &&
          this.crewfare_title.length > 0 &&
          this.crewfare_members.length > 0
        );
      }
      return false;
    },
    rooms() {
      const roomsList = [];
      this.rfp.data().roomList.forEach((day) => {
        roomsList.push({
          day: day.date,
          ...day.rooms,
        });
      });
      return roomsList;
    },
    endDateFormated() {
      if (!this.rfp.data().endDate) return "";
      const date = this.rfp.data().endDate;
      return getMomentFromFirebaseDate(date).format("MM-DD-YYYY");
    },
    startDateFormated() {
      if (!this.rfp.data().startDate) return "";
      const date = this.rfp.data().startDate;
      return getMomentFromFirebaseDate(date).format("MM-DD-YYYY");
    },
    effectiveDateFormated() {
      if (!this.rfp.data().effective_date) return "";
      const date = this.rfp.data().effective_date;
      return getMomentFromFirebaseDate(date).format("MM-DD-YYYY");
    },
    account() {
      return this.$store.state.account;
    },
    getDefaultContractContent() {
      return `
        <div style="padding: 0 12px">
          <h2 class="text-xl text-center mb-4">CREWFARE HOTEL AGREEMENT</h2>
          <div style="margin-top: 12px" ${this.protect_component}>
            This Master Hotel Agreement (this "Agreement") is made and entered into as
            of
            <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">{{effective_date_formated}}</span>
            (the "Effective Date") by and between Crewfare LLC ("Group") and
            <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">{{hotel_name}}</span>
            (the "Hotel"). Group and Hotel are sometimes referred to herein
            individually as a "Party" and collectively as the "Parties".
          </div>
          <div style="margin-top: 12px" class="text-xl font-bold text-center py-4" ${this.protect_component}>
            TENTATIVE NAME OF EVENT: <span style="color: rgb(202, 138, 4); font-weigth: bold">{{rfp_name}}</span> (the "Event").
          </div>
          <table
            style="margin-top: 12px; width: 100%"
            cellpadding="0"
            cellspacing="0"
            ${this.protect_component}
          >
            <tr>
              <td valign="top" width="50%">
                <h3 class="text-xl">GROUP:</h3>
                <div style="margin-top: 12px">Company: Crewfare LLC</div>
                <div style="margin-top: 12px">Name: Jason van Esso</div>
                <div style="margin-top: 12px">Title: Partner</div>
                <div style="margin-top: 12px">
                  Address: 2678 Edgewater Court, Weston FL 33332
                </div>
                <div style="margin-top: 12px">Telephone: 954-591-9005</div>
                <div style="margin-top: 12px">Email: jason@crewfare.com</div>
              </td>
              <td valign="top" width="50%">
                <h3 class="text-xl">HOTEL:</h3>
                <div style="margin-top: 12px">
                  Hotel Name (that the group will be staying): <span style="color: rgb(202, 138, 4); font-weigth: bold">{{hotel_name}}</span>
                </div>
                <div style="margin-top: 12px" class="flex gap-2 items-center">
                  Name:
                  <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">{{hotel_person_name}}</span>
                </div>
                <div style="margin-top: 12px" class="flex gap-2 items-center">
                  Title:
                  <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">{{hotel_title}}</span>
                </div>
                <div style="margin-top: 12px" class="flex gap-2 items-center">
                  Address (that the group will be staying):
                  <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">{{hotel_address}}</span>
                </div>
                <div style="margin-top: 12px" class="flex gap-2 items-center">
                  Telephone:
                  <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">{{hotel_telephone}}</span>
                </div>
                <div style="margin-top: 12px" class="flex gap-2 items-center">
                  Email:
                  <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">{{hotel_email}}</span>
                </div>
              </td>
            </tr>
          </table>
          <h3 style="margin-top: 12px" class="text-xl">1. TERMS.</h3>
          <div style="margin-top: 12px">
            <span class="underline">A. Reservation of Rooms.</span> The Hotel will
            reserve, exclusively for the Event's team, staff, fans, or other guests
            (collectively the "Guests" or the "Group" or the "Patrons"), sleeping
            rooms (the "Rooms") on the event dates below (the "Reservation Period")
            subject to the terms of this Agreement.
          </div>
          <div style="margin-top: 12px">
            <span class="underline">B. Courtesy Room Block.</span> Room rates have
            been established for Group's room block ("Room Block"). The Hotel is
            obligated to hold the Room Block available to the Group up until the
            Cut-Off Date. There is no commitment by Group to pick up these rooms.
          </div>
          <div style="margin-top: 12px" ${this.protect_component}>
            <span class="underline">C. Room Assignments.</span>
            No later than
            <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">{{cutoff_days}}</span>
            days prior to the Event Date (the "Cut-Off Date")
            <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">{{cutoff_date_formated}}</span>, the Group will notify the Hotel of such assignments by providing a
            Rooming List to the Hotel with the assigned names to the Hotel. It is
            expressly agreed and understood that the Group makes no representations
            that the number of Rooms reserved under this Agreement will be assigned.
            Only assigned Rooms will be paid for. After the Cut-off Date, Rooms
            reserved under this Agreement, but not assigned to specific Patrons, may
            be released, at no cost to the Group, for sale to the general public.
            After the Cut-off Date, Hotel will accept additional reservations from the
            Group, subject to availability, at Group Rates
          </div>
          <div style="margin-top: 12px">
            <span class="underline">D. Room Types.</span>
          </div>
          {{room_types_table}}
          <div style="margin-top: 12px"  ${this.protect_component}>
            <span class="underline">E. Room Inventory (the "Inventory").</span>&nbsp;
            <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">{{start_date_formated}}</span>
            through
            <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">{{end_date_formated}}</span>
            shall be the "Peak Nights". The number of rooms below shall be allocated
            and committed to Group as Rooms for the Guests.
          </div>
          {{room_list_table}}
          <div style="margin-top: 12px">
            <span class="underline">F. Room Rates. (the "Room Rates").</span>
          </div>
          {{room_rate_table}}
        {{group_honored_rate}}
        <div style="margin-top: 12px">
          <span class="underline">G. Taxes & Fees.</span> Rates above are quoted
          exclusive of applicable state and local taxes, fees and other charges
          known as the "Ancillary Costs". All Ancillary Costs must be stated in the
          below chart in order for the Hotel to be compensated for those fees
        </div>
        <table
          style="
            width: 100%;
            border-color: #000;
            border-collapse: collapse;
            margin-top: 12px;
          "
          border="1"
          class="w-full"
          ${this.protect_component}
        >
          <tr>
            <th class="px-4 py-1 border border-black text-center">Charge</th>
            <th class="px-4 py-1 border border-black text-center">
              Per Room Night
            </th>
          </tr>
          <tr>
            <td class="px-4 py-1 border border-black">Local Occupancy Tax</td>
            <td class="px-4 py-1 border border-black text-center">
              <span>
                <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">
                  {{hotel_local_occupancy_tax}}
                </span>
              </span>
            </td>
          </tr>
          <tr>
            <td class="px-4 py-1 border border-black">State Occupancy Tax</td>
            <td class="px-4 py-1 border border-black text-center">
              <span>
                <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">
                  {{hotel_state_occupancy_tax}}
                </span>
              </span>
            </td>
          </tr>
          <tr>
            <td class="px-4 py-1 border border-black">City Tax</td>
            <td class="px-4 py-1 border border-black text-center">
              <span>
                <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">
                  {{hotel_city_tax}}
                </span>
              </span>
            </td>
          </tr>
          <tr>
            <td class="px-4 py-1 border border-black">Convention & Tourism Tax</td>
            <td class="px-4 py-1 border border-black text-center">
              <span>
                <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">
                  {{hotel_convention_tax}}
                </span>
              </span>
            </td>
          </tr>
          <tr>
            <td class="px-4 py-1 border border-black">VAT Tax</td>
            <td class="px-4 py-1 border border-black text-center">
              <span>
                <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">
                  {{hotel_vat_tax}}
                </span>
              </span>
            </td>
          </tr>
          <tr>
            <td class="px-4 py-1 border border-black">SAT Tax</td>
            <td class="px-4 py-1 border border-black text-center">
              <span>
                <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">
                  {{hotel_sat_tax}}
                </span>
              </span>
            </td>
          </tr>
          <tr>
            <td class="px-4 py-1 border border-black">Municipal Accommodation Tax</td>
            <td class="px-4 py-1 border border-black text-center">
              <span>
                <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">
                  {{hotel_municipal_accomodation_tax}}
                </span>
              </span>
            </td>
          </tr>
          <tr>
            <td class="px-4 py-1 border border-black">HST Tax</td>
            <td class="px-4 py-1 border border-black text-center">
              <span>
                <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">
                  {{hotel_hst_tax}}
                </span>
              </span>
            </td>
          </tr>
          <tr>
            <td class="px-4 py-1 border border-black">Sustaintability Fee</td>
            <td class="px-4 py-1 border border-black text-center">
              <span>
                <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">
                  {{hotel_sustaintability_fee}}
                </span>
              </span>
            </td>
          </tr>
          <tr>
            <td class="px-4 py-1 border border-black">Other Taxes</td>
            <td class="px-4 py-1 border border-black text-center">
              <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">
                {{hotel_taxes}}
              </span>
            </td>
          </tr>
          <tr>
            <td class="px-4 py-1 border border-black">Resort Fee</td>
            <td class="px-4 py-1 border border-black text-center">
              <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">
                {{resort_fee}}
              </span>
            </td>
          </tr>
          <tr>
            <td class="px-4 py-1 border border-black">Other Fees</td>
            <td class="px-4 py-1 border border-black text-center">
              <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">
                {{other_fees}}
              </span>
            </td>
          </tr>
        </table>
        <div style="margin-top: 12px">
          <span class="underline">H. Pricing Confidentiality.</span> The prices
          contained in this Agreement are considered confidential and Hotel will not
          disclose such information to any third parties or Patrons without Group's
          prior written consent.
        </div>
        <div style="margin-top: 12px">
          <span class="underline">I. Rate Integrity.</span> If, from the date of
          contracting through the Cut-Off Date, it is determined that a qualifying
          lower single/double rate for the same room type, dates, bed type, number
          of guests, same or better restrictions and policies is offered to other
          guests over the Group's scheduled dates, Hotel agrees to use commercially
          reasonable efforts to promptly remove the lower published rate from all
          distribution channels. This commitment only applies to direct published
          rates made available to the general public.
        </div>
        <div style="margin-top: 12px"  ${this.protect_component}>
          <span class="underline">J. Commissions and Rebates.</span> Rooms occupied
          and actualized by Guests shall include a
          <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">{{commission}}</span>
          commission and
          <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">{{rebate}}</span>
          rebate, payable per actualized room night booked in the Room Block and all
          room nights found outside of the Room Block, regardless of actual guest
          room rate. For cancelled reservations or No-Shows where the reservation
          was guaranteed with a deposit or major credit card, commission/rebates are
          due on any charges that Hotel is able to collect from the Patrons causing
          the cancellation or No-Show, as applicable. Any commission or rebate must
          be detailed in a final folio to Group, sent no later than seven (7) days
          after the Event. Commissions and rebates are payable to Crewfare LLC (IATA
          #:
          <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">{{iata}}</span>) no later than thirty (30) days from invoice date.
        </div>
          <div style="margin-top: 12px" ${this.protect_component}>
            <span class="underline">K. Event Planner Program.</span> Jason van Esso
            (collectively, the "Event Planner") is eligible to earn an event planner
            bonus for qualifying events. Event Planner is eligible to earn
            <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">{{reward_points}}</span>. The Event Planner's member number is
            <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">{{crewfare_members}}</span>.
          </div>
          <div style="margin-top: 12px" ${this.protect_component}>
            <span class="underline">L. Complimentary Rooms.</span> For its use during
            the dates of the Event, and ancillary planning dates, Group will be
            entitled to
            <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">{{comp_rooms}}</span>
            complimentary room night for every room nights occupied, on a cumulative
            basis. Hotel agrees to credit to Group's Master Account the actual value
            of the earned complimentary rooms.
          </div>
          <div style="margin-top: 12px">
            <span class="underline">M. Exclusive Right to Use Rooms.</span> Hotel
            acknowledges and agrees that Group shall have the exclusive right to sell,
            rent, or otherwise assign the Rooms. It is expressly agreed that where the
            Group resells Rooms, the rates that Group charges may vary from the Room
            Rates listed in this Agreement. Hotel agrees (i) to keep the rate charged
            to the Group for any given Room confidential between the Parties, and (ii)
            not to disclose the rate charged to the Group to any Patrons, whether
            directly or indirectly, under any circumstances. Notwithstanding anything
            to the contrary in this Agreement, substitution of any reservations (i.e.,
            a change to the name/details included on the Rooming List) by the Group
            shall be allowed at any time up to and through the time of the Event.
          </div>
          <h3 class="text-xl">2. HOTEL TAXES AND OTHER FEES.</h3>
          <div style="margin-top: 12px">
            Hotel sleeping room rates are subject to applicable state and local taxes
            in effect at the time of check-in. During the term of this Agreement,
            Hotel shall not impose any other mandatory charge on guests or master
            accounts associated with the Group or the Event. Hotel will notify Group
            of the tax requirements and any changes in the tax requirements.
          </div>
          <div style="margin-top: 12px">
            <span class="underline">A. Master Account & Payment Arrangements.</span>
            Master account credit card will the expenses for the room and applicable
            tax for the room only. All Guests must provide credit card at check-in for
            their own incidental charges, resort fees and other charges. Group shall
            not be responsible for any incidental charges for any Hotel guests or any
            other charges without written consent from Group
          </div>
          <div style="margin-top: 12px">
            <span class="underline">B. Deposit Schedule.</span> Group and Hotel agree
            to the following deposit schedule. Hotel agrees to obtain approval from
            Crewfare's accounting team,
            <a href="mailto:accounting@crewfare.com" class="underline">
              accounting@crewfare.com</a>, (Tony Tohme) before making any such charges to the credit card on file
            at any time
          </div>
          <table
            style="
              margin-top: 12px;
              width: 100%;
              border-color: #000;
              border-collapse: collapse;
            "
            border="1"
            class="w-full"
          >
            <tr>
              <th></th>
              <th class="border border-black px-4 py-2">Due Date</th>
              <th class="border border-black px-4 py-2">
                Amount (Not Including Service Charge or Tax)
              </th>
            </tr>
            <tr>
              <th class="border border-black px-4 py-2">Upon Signing</th>
              <td class="border border-black px-4 py-2">Upon signing of Agreement</td>
              <td class="border border-black px-4 py-2">
                Credit Card Guarantee Only ($0.00 deposit)
              </td>
            </tr>
            <tr>
              <th class="border border-black px-4 py-2">Second Deposit</th>
              <td class="border border-black px-4 py-2">At Cut-Off Date:</td>
              <td class="border border-black px-4 py-2">
                25% of Anticipated Master Account Balance (Based On Group Pick Up)
              </td>
            </tr>
            <tr>
              <th class="border border-black px-4 py-2">Final Deposit</th>
              <td class="border border-black px-4 py-2">3 Days Ahead of Arrival:</td>
              <td class="border border-black px-4 py-2">
                100% Of Anticipated Master Account Balance.
              </td>
            </tr>
          </table>
          <div style="margin-top: 12px">
            <span class="underline">C. Early Departure.</span> Neither Group nor the
            Guests will be charged for early departure fees.
          </div>
          <div style="margin-top: 12px">
            <span class="underline">D. No-Shows.</span> Hotel agrees that it will not
            release a No-Show accommodation without prior approval from Crewfare. A
            No-Show room night billed to the master account is considered an
            actualized room night and will count towards Group's final pick-up.
          </div>
          <div style="margin-top: 12px">
            <span class="underline">E. Construction, Renovation, Hotel Closings.</span>
            Hotel agrees to immediately notify the Group of any plans for renovation
            or construction. Under such circumstances, the Room Rate shall be reduced
            by fifty percent (50%) and the Group shall have the right to reduce or
            terminate their planned use of the Hotel upon written notice to Hotel and
            if so terminated, the Group shall be indemnified by the Hotel for any
            costs and expenses incurred in hosting the Patrons at the Hotel.
          </div>
          <div style="margin-top: 12px">
            <span class="underline">F. Americans with Disabilities Act.</span> As
            among the parties, Hotel shall be responsible for compliance with the
            Americans with Disabilities Act ("ADA") including, but not limited to,
            compliance with respect to: (i) the Hotel's policies practices, procedures
            and eligibility criteria; (ii) the provision of auxiliary aids and
            services in the Hotel; (iii) architectural, communications and
            transportation barriers in the Hotel; and (iv) the provision of wheelchair
            seating spaces in assembly areas
          </div>
          <div style="margin-top: 12px">
            <span class="underline">G. Insurance.</span> Hotel will maintain full
            insurance coverage throughout the term of this Agreement, in such amounts,
            coverages, types and insurers as are maintained by other hotels of similar
            quality in the State of Florida, against all third party liability,
            including insurance in respect of liability to guests and others using the
            hotel facilities and liability to the employees and agents of the Hotel,
            and will supply certificates of insurance to the Group upon request.
          </div>
          <div style="margin-top: 12px">
            <span class="underline">H. Change in Hotel Management or Ownership.</span>
            Hotel shall notify Group of any change in Hotel management or ownership.
            Group shall have the right to terminate this Agreement without liability
            if, after the time of contracting but before the Event, the Hotel changes
            the hotel brand, such that the level of service provided by the new brand
            is lower than Hotel's original brand at the time of contracting. Any
            change in the point of contact must also be communicated to the Group.
            Conversely, new management or ownership agrees to honor Group under terms
            set forth in this agreement
          </div>
          <div style="margin-top: 12px">
            <span class="underline">I. Concessions</span>
          </div>
          <ol class="upgrade-list" ${this.protect_component}>
            <li>
              Suite upgrades:
              <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">
                {{suite_upgrades}}</span>
            </li>
            <li>
              Wi-fi:
              <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">
                {{wifi}}</span>
            </li>
            <li>
              Parking:
              <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">
                {{parking}}</span>
            </li>
            <li>
              Breakfast:
              <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">{{breakfast}}</span>
            </li>
            <li>
              Additional terms:
              <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">{{terms}}</span>
            </li>
            <li>Check-In Age: <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">{{hotel_check_in_age}}</span></li>
            <li>Check-In Time: <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">{{hotel_check_in_time}}</span></li>
            <li>Check-Out Time: <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900">{{hotel_check_out_time}}</span></li>
          </ol>
          <div style="margin-top: 12px">
            <span class="font-bold">3. COMPLIANCE WITH LAW.</span> Each of the Parties
            undertakes to comply with all applicable laws, rules regulations and
            ordinances, whether applicable nationally, at federal, state or local
            level or by a competent foreign lawmaker. For the avoidance of doubt, this
            includes applicable laws, rules, regulations and ordinances which relate
            to the processing of personal data/personally identifiable information.
          </div>
          <div style="margin-top: 12px">
            <span class="font-bold">4. ACCEPTANCE.</span> Once executed, this
            Agreement shall be effective as of the date first above mentioned (the
            "Effective Date")
          </div>
          <div style="margin-top: 12px">
            <span class="font-bold">5. INTELLECTUAL PROPERTY.</span> Hotel shall not
            use any Intellectual Property Rights of Group, Event, or any of their
            respective affiliates. No such rights are granted pursuant to this
            Agreement. For these purposes, "Intellectual Property Rights" means all
            patents, trademarks, and copyright of Group or Event
          </div>
          <div style="margin-top: 12px">
            <span class="font-bold">6. INDEMNIFICATION.</span> Hotel agrees to
            indemnify, defend and hold harmless Group, Group's owners, managers,
            partners, subsidiaries, affiliates, officers, directors, employees and
            agents (collectively, the "Group Indemnified Parties"), from and against
            any and all Claims (as such term is defined above) arising out of or
            relating to the Event that is the subject of this Agreement but only to
            the extent any such Claim(s) to the extent any such Claim(s) arise out of
            the negligence, gross negligence or intentional misconduct of Hotel's
            employees, agents, or contractors.
          </div>
          <h3 class="text-xl">7. MISCELLANEOUS.</h3>
          <div style="margin-top: 12px">
            <span class="font-bold">A.</span> Severability Hotel agrees that it will
            not release a No-Show accommodation without prior approval from Crewfare.
            A No-Show room night billed to the master account is considered an
            actualized room night and will count towards Group's final pick-up.
          </div>
          <div style="margin-top: 12px">
            <span class="font-bold">B.</span> Entire Agreement This Agreement,
            including any attachments, constitutes the entire agreement of the parties
            and its provisions supersede any and all prior and contemporaneous
            agreements or understandings relating to the same subject matter
          </div>
          <div style="margin-top: 12px">
            <span class="font-bold">C.</span> Governing Law. This Agreement shall be
            governed and construed in accordance with the laws of the State of
            Florida, USA, without regard to its choice-of-law principles. Any claim
            arising under this Agreement shall be prosecuted exclusively in a federal
            or state court of competent jurisdiction located within Miami-Dade
            County,Florida, USA and the parties consent to the jurisdiction of such
            court and to the service of process by mail. Notwithstanding anything to
            the contrary contained herein, each Party hereby waives, to the maximum
            extent not prohibited by law, any right it may have to claim or recover in
            any legal action or proceeding arising under this Agreement any special,
            exemplary, punitive or consequential damages.
          </div>
          <div style="margin-top: 12px">
            <span class="font-bold">D.</span> Successors and Assigns. This Agreement
            may not be transferred or assigned by any party without the prior written
            consent of the other parties, which may not be unreasonably withheld or
            delayed, provided that F1R may assign this Agreement to its subsidiary
            undertakings, its holding company or holding companies and other
            subsidiary undertakings of each of its holding companies from time to
            time, each, an "Affiliate", and "Affiliates" shall mean all or more than
            one such Affiliate. Any attempted assignment or transfer in violation of
            this Section shall be void and a material breach of this Agreement. This
            Agreement will be binding upon any authorized successors or assigns. Prior
            to any transfer or assignment of this Agreement, the party seeking to
            assign or transfer this Agreement shall obtain a written acknowledgement
            of the binding nature of this Agreement from the successor or assignee
          </div>
          <div style="margin-top: 12px">
            <span class="font-bold">E.</span> Authority Hotel hereby represents and
            warrants to Crewfare that: (i) Hotel has the authority to enter into this
            Agreement; (ii) its execution and performance of this Agreement does not
            and will not conflict with or violate any law, regulation or agreement by
            which it is bound; and (iii) Hotel has obtained all consents, approvals
            and/or authorizations necessary to make this Agreement binding upon the
            Hotel, the owner of the Hotel, Hotel's management and all other persons or
            entities which ay have any interest in the Hotel, its management and/or
            premises and upon any and all successors purchaser or transferees which
            may obtain any interest in Hotel, its management and/or premises during
            the term of this Agreement. At Crewfare's request, Hotel shall use its
            best efforts to obtain a written non-interference agreement from any
            person or entity which may have any ownership, mortgage or deb-related
            interest in Hotel. This non-interference agreement shall provide that any
            successor-in-title by foreclosure or to other conveyance will be bound by
            this Agreement.
          </div>
          <div style="margin-top: 12px">
            <span class="font-bold">F.</span> Notices. All notices, demands, requests
            or other communications relating to this agreement shall be in writing and
            shall be mailed, first class, or transmitted by hand delivery or email,
            addressed to the contacts as included in the beginning of the Agreement.
          </div>
          <div style="margin-top: 12px">
            <span class="font-bold">G.</span> Amendments. This Agreement may not be
            modified, amended, terminated or otherwise changed, except by a written
            instrument executed by each of the Parties.
          </div>
          <div style="margin-top: 12px">
            <span class="font-bold">H.</span> Interpretation. No provisions of this
            Agreement shall be construed against or interpreted to the disadvantage of
            any party to this Agreement by any court or other governmental or judicial
            authority by reason of such party having or being deemed to have drafted
            or dictated such provision.
          </div>
          <div style="margin-top: 12px">
            <span class="font-bold">I.</span> Independent Contractors. The status of
            the Parties under this Agreement shall be that of independent contractors.
            No Party shall be authorized to waive any right, or assume or create any
            contract or obligation of any kind in the name of, or on behalf of, the
            others or to make any statement that it has the authority to do so.
            Nothing in this Agreement shall be construed as establishing a
            partnership, joint venture, agency, employment or other similar
            relationship among the parties hereto
          </div>
          <div style="margin-top: 12px">
            <span class="font-bold">J.</span> Waiver. The failure of any party to
            enforce any condition or part of this Agreement at any time shall not be
            construed as a waiver of that condition or part, nor shall it forfeit any
            rights to future enforcement thereof.
          </div>
          <div style="margin-top: 12px">
            <span class="font-bold">K.</span> Counterparts. This Agreement may be
            executed and delivered in counterparts, each of which shall be deemed an
            original and all of which, taken together, shall constitute one
            instrument. Copies of executed counterparts of this Agreement transmitted
            by email shall be considered original executed counterparts provided that
            receipt of such email is confirmed.
          </div>
          <div style="margin-top: 12px">
            <span class="font-bold">L.</span> Costs Each Party shall bear its own
            costs in connection with the preparation, negotiation and execution of
            this Agreement
          </div>
          <div style="margin-top: 12px">
            <span class="font-bold">M.</span> Force Majeure. Neither party shall be
            liable for any failure or delay in performance of its obligations under
            this Agreement arising out of or caused, directly or indirectly, by
            circumstances beyond its reasonable control, including, without
            limitation, cancellation of the Event for whatever reason; government
            regulations prohibiting travel to the city in which the Hotel is located
            over the Event dates and/or the issuance of a "Level 3 Travel Warning" by
            the Centers for Disease Control that specifically advises travelers to
            avoid all non-essential travel to the city in which the Hotel or the Event
            is located and which travel advisory is in effect over the Event dates;
            acts of God; earthquakes; fires; floods; wars; civil or military
            disturbances; acts of terrorism; sabotage; strikes; epidemics; riots;
            power failures; computer failure and any such circumstances beyond its
            reasonable control as may cause interruption, loss or malfunction of
            utility, transportation, computer (hardware or software) or telephone
            communication service; accidents; labor disputes; acts of civil or
            military authority; governmental actions; or inability to obtain labor,
            material, equipment or transportation. The affected party may terminate
            this Agreement without liability upon providing written notice to the
            other party within ten (10) days of the occurrence. If the Event is
            properly cancelled this shall be considered a valid impossibility force
            majeure occurrence, then upon written request by Group, Hotel agrees to
            refund to Group all prepaid deposits or advance payments paid to Hotel
            without deduction, less any expenses incurred by Hotel in preparation for
            the Event.
          </div>
          <div style="margin-top: 12px">
            IN WITNESS WHEREOF, the Parties have executed this Agreement as of the
            Effective Date.
          </div>
        </div>
      `;
    },
  },
  async mounted() {
    this.crewfare_signature = jasonSignature;
    this.getData();
    document.title = `Launchpad - Crewfare - RFP - Contract`;

    window.addEventListener("resize", () => {
      if (this.$refs.signaturePadCrewfare)
        this.$refs.signaturePadCrewfare.fromDataURL(this.crewfare_signature);
      if (this.$refs.signaturePadHotel)
        this.$refs.signaturePadHotel.fromDataURL(this.hotel_signature);
    });
  },
  methods: {
    validateInputs(){
      if(this.hasBeenSigned){
        return;
      }
      //Remove any special characters from the input
      if(this.isHotel){
        this.hotel_person_name = this.hotel_person_name.replace(/[^a-zA-Z0-9 .]/g, "");
        this.hotel_title = this.hotel_title.replace(/[^a-zA-Z0-9 .]/g, "");
      }
      else{
        this.crewfare_title = this.crewfare_title.replace(/[^a-zA-Z0-9 .]/g, "");
        this.crewfare_name = this.crewfare_name.replace(/[^a-zA-Z0-9 .]/g, "");
      }
    },
    async restartSigningProcess() {
      if (!confirm("Are you sure you want to restart the signing process?"))
        return;
      const status = "Accepted by both sides";
      const rfpData = {
        ...this.rfp.data(),
        status,
      };
      delete rfpData.signed;
      const data = await setStatusHistory({
        data: rfpData,
        content: "Signing process restart",
        status,
      });
      await setDoc(doc(firestore, "rfp_hotel", this.rfp.id), data);
      this.$store.commit("setToast", {
        content: `<div class='flex flex-col gap-2'>
                <div style="margin-top: 12px">${
                  this.rfp.data().name
                } signing process restarted!</div>
              </div>`,
      });
      router.push({
        name: "rfpsHotels",
        params: { id: this.rfp.data().rfp },
      });
    },
    getTotal() {
      const roomList = this.rfp.data().roomList;
      const total = roomList.map((day) =>
        day.rooms
          .map((room) => parseFloat(room.amount || 0))
          .reduce((accumulator, value) => accumulator + value, 0)
      );
      return total.reduce((accumulator, value) => accumulator + value, 0);
    },
    getTotalByType(indexType) {
      const roomList = this.rfp.data().roomList;
      const total = roomList.map((day) =>
        day.rooms
          .filter((room, indexType) => indexType === indexType)
          .map((room) => parseFloat(room.amount || 0))
      );
      return total.reduce(
        (accumulator, value) => accumulator + parseFloat(value[0]),
        0
      );
    },
    getTotalByDay(rooms) {
      const amount = rooms.map((room) => parseFloat(room.amount || 0));
      return amount.reduce((accumulator, value) => accumulator + value, 0);
    },
    getTypeLoop() {
      let tdTypes = "";
      if (!this.types) return;
      this.types.forEach((date) => {
        tdTypes += `<td style="padding: 4px 6px;text-align: center;">
          <span>
          <span style="color: rgb(202, 138, 4); font-weigth: bold"
            ${this.protect_component} data-original-variable="{{room_type}}">
            ${date}
            </span>
            </span>
          </td>`;
      });
      return tdTypes;
    },
    getDateLoop() {
      let tdDates = "";
      if (!this.types) return;
      this.dates.forEach((date) => {
        tdDates += `<td style="padding: 4px 6px;text-align: center;">
          <span>
          <span style="color: rgb(202, 138, 4); font-weigth: bold"
            ${this.protect_component} data-original-variable="{{room_type}}">
            ${date}
            </span>
            </span>
          </td>`;
      });
      return tdDates;
    },
    getTotalByTypeLoop() {
      let tdTypes = "";
      if (!this.dates) return;
      this.dates.forEach((type, index) => {
        tdTypes += `<td style="padding: 4px 6px;text-align: center;"
          >
          <span>
          <span style="color: rgb(202, 138, 4); font-weigth: bold"
            ${this.protect_component} data-original-variable="{{room_type}}">
            ${this.getTotalByType(index)}</span></span>
          </td>`;
      });
      return tdTypes;
    },
    getTotalByDateLoop() {
      let tdTypes = "";
      this.dates.forEach((date) => {
        let amount = 0;
        this.rfp
          .data()
          .roomList.filter((day) => day.date === date)
          .forEach((days) => {
            amount = days.rooms
              .map((day) => day.amount)
              .reduce(
                (accumulator, value) => accumulator + parseFloat(value),
                0
              );
          });
        tdTypes += `<td style="padding: 4px 6px;text-align: center;"
          >
          <span>
          <span style="color: rgb(202, 138, 4); font-weigth: bold"
            ${this.protect_component} data-original-variable="{{room_type}}">
            ${amount}</span></span>
          </td>`;
      });
      return tdTypes;
    },
    roomRateLoop() {
      let trRoomRate = "";
      this.types.forEach((type, typeIndex) => {
        const typeValue = type.name || type;
        trRoomRate += `<tr>
        <td
              style="padding: 4px 6px;text-align: left;">
          <span>
          <span style="color: rgb(202, 138, 4); font-weigth: bold"
            ${this.protect_component} data-original-variable="{{room_type}}">
          ${typeValue}</span></span>
        </td>`;
        this.rfp.data().roomList.forEach((days) => {
          trRoomRate += `<td style="padding: 4px 6px;text-align: center;">
          <span>
          <span style="color: rgb(202, 138, 4); font-weigth: bold"
            ${this.protect_component} data-original-variable="{{room_type}}">
            ${
              days.rooms.find((room, index) => index === typeIndex)?.value || 0
            }</span></span>
          </td>`;
        });
        trRoomRate += `</tr>`;
      });
      return trRoomRate;
    },
    getRoomListLoop() {
      let trRoomList = "";
      this.types.forEach((type, typeIndex) => {
        const typeValue = type.name || type;
        trRoomList += `<tr>
        <td
              style="padding: 4px 6px;text-align: left;">
          <span>
          <span style="color: rgb(202, 138, 4); font-weigth: bold"
            ${this.protect_component} data-original-variable="{{room_type}}">
          ${typeValue}</span></span>
        </td>`;
        let total = 0;
        this.rfp.data().roomList.forEach((days) => {
          const amount = parseFloat(
            days.rooms.find((room, index) => index === typeIndex)?.amount || 0
          );
          total += amount;
          trRoomList += `<td style="padding: 4px 6px;text-align: center;">
          <span>
          <span style="color: rgb(202, 138, 4); font-weigth: bold"
            ${this.protect_component} data-original-variable="{{room_type}}">
            ${amount}</span></span>
          </td>`;
        });
        trRoomList += `<td style="padding: 4px 6px;text-align: center;">
          <span>
          <span style="color: rgb(202, 138, 4); font-weigth: bold; text-align: center;"
            ${this.protect_component} data-original-variable="{{room_type}}">
            ${total}</span></span></td></tr>`;
      });
      return trRoomList;
    },
    getResortFee() {
      return this.rfp.data().resortFee === "Resort Fee"
        ? this.rfp.data().resortFeeAmount
        : this.rfp.data().resortFee;
    },
    getOtherFees() {
      return !this.rfp.data().otherFees
        ? "No"
        : this.rfp.data().otherFees === "Yes"
        ? this.rfp.data().otherFeesAmount
        : this.rfp.data().otherFees;
    },
    getOccupancyLoop() {
      let trOccupancyList = "";
      let types = this.rfp.data().roomTypes;
      if (!types) {
        types = [
          {
            name: "King",
            type: "Single",
            configuration: "1 King Bed",
            occupancy: "2",
            extra_value: "",
          },
          {
            name: "Double Queen",
            type: "Double",
            configuration: "Double Queen Beds",
            occupancy: "4",
            extra_value: "",
          },
        ];
      }
      types.forEach((days) => {
        trOccupancyList += `<tr>
          <td style="padding: 4px 6px;text-align: left;">
          <span>
          <span style="color: rgb(202, 138, 4); font-weigth: bold"
            ${this.protect_component} data-original-variable="{{room_type}}">
            ${days.name}</span></span>
          </td>
          <td style="padding: 4px 6px;text-align: left;">
          <span>
          <span style="color: rgb(202, 138, 4); font-weigth: bold"
            ${this.protect_component} data-original-variable="{{room_type}}">
            ${days.type || "-"}</span></span>
          </td><td style="padding: 4px 6px;text-align: left;">
          <span>
          <span style="color: rgb(202, 138, 4); font-weigth: bold"
            ${this.protect_component} data-original-variable="{{room_type}}">
            ${days.configuration || "-"}</span></span>
          </td><td style="padding: 4px 6px;text-align: left;">
          <span>
          <span style="color: rgb(202, 138, 4); font-weigth: bold"
            ${this.protect_component} data-original-variable="{{room_type}}">
            ${days.occupancy || "-"}</span></span>
          </td><td style="padding: 4px 6px;text-align: left;">
          <span>
          <span style="color: rgb(202, 138, 4); font-weigth: bold"
            ${this.protect_component} data-original-variable="{{room_type}}">
            ${days.extra_value || "-"}</span></span>
          </td>
          </tr>`;
      });
      return trOccupancyList;
    },
    getRoomListTable() {
      return `<table
            style="
              margin-top: 12px;
              width: 100%;
              border-color: #000;
              border-collapse: collapse;
            "

            border="1"
            class="w-full"
          >
            <tr>
              <td  style="padding: 4px 6px;text-align: left;">Room Name<br/>
                (as seen on your website)</td>
              ${this.getDateLoop()}
              <td  style="padding: 4px 6px;text-align: center;">Total</td>
            </tr>
            ${this.getRoomListLoop()}
            <tr>
              <td  style="padding: 4px 6px;text-align: left;"></td>
              ${this.getTotalByDateLoop()}
              <td style="padding: 4px 6px;text-align: center;">
          <span>
          <span style="color: rgb(202, 138, 4); font-weigth: bold"
            ${this.protect_component} data-original-variable="{{room_type}}">
                ${this.getTotal()}
                </span></span>
              </td>
            </tr>
          </table>`;
    },
    getRoomTypeTable() {
      return `<table
            style="
              margin-top: 12px;
              width: 100%;
              border-color: #000;
              border-collapse: collapse;
            "
            border="1"
            class="w-full"
          >
            <tr>
              <td  style="padding: 4px 6px;text-align: left;">Room Name<br/>
                (as seen on your website)</td>
              <td  style="padding: 4px 6px;text-align: center;">Room Type</td>
              <td  style="padding: 4px 6px;text-align: center;">Room Configuration</td>
              <td  style="padding: 4px 6px;text-align: center;">Hotel Room<br/>Occupancy Limit</td>
              <td  style="padding: 4px 6px;text-align: center;">Additional Person<br/>Value</td>
            </tr>
            ${this.getOccupancyLoop()}
          </table>`;
    },
    getRoomRateTable() {
      return `<table
          style="
            margin-top: 12px;
            width: 100%;
            border-color: #000;
            border-collapse: collapse;
          "
          border="1"
          class="w-full"
          ${this.protect_component}
        >
          <tr>
            <td style="padding: 4px 6px;text-align: left;">Room Name <br/>(As seen in your website)</td>
            ${this.getDateLoop()}
          </tr>
          ${this.roomRateLoop()}
        </table>`;
    },
    getGroupHonoredRate() {
      return this.rfp.data().groupDateHonored === "No"
        ? `
        <div style="margin-top: 12px" ${this.protect_component}>
          The group rate is
          ${
            this.rfp.data().groupDateHonoredPre
              ? `
          <span>
            <span>
            <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900"
              ${this.protect_component} data-original-variable="{{room_type}}"
              >$ ${this.rfp.data().groupDateHonoredPreRate}</span>
              </span>
            pre
          </span>
          `
              : ""
          }
          ${
            this.rfp.data().groupDateHonoredPre &&
            this.rfp.data().groupDateHonoredPost
              ? `
          <span>&nbsp;and&nbsp;</span>
          `
              : ""
          }
          ${
            this.rfp.data().groupDateHonoredPost
              ? `
          <span>
            <span>
            <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900"
              ${this.protect_component} data-original-variable="{{room_type}}"
              >$ ${this.rfp.data().groupDateHonoredPost}</span></span>
            post
          </span>
          `
              : ""
          }
          on "Shoulder Nights" known as +/-
          ${
            this.rfp.data().groupDateHonoredPre
              ? `
          <span>
            <span>
            <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900"
              ${this.protect_component} data-original-variable="{{room_type}}"
              >${this.rfp.data().groupDateHonoredPreDays}</span></span>
            pre
          </span>
          `
              : ""
          }
          ${
            this.rfp.data().groupDateHonoredPre &&
            this.rfp.data().groupDateHonoredPost
              ? `
          <span>&nbsp;and&nbsp;</span>
          `
              : ""
          }
          ${
            this.rfp.data().groupDateHonoredPost
              ? `
          <span>
            <span>
            <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900"
              ${this.protect_component} data-original-variable="{{room_type}}"
              >${this.rfp.data().groupDateHonoredPostDays}</span></span>
            post
          </span>
          `
              : ""
          }
          the "Peak Nights".
        </div>
        `
        : "" + this.rfp.data().groupDateHonored === "Yes"
        ? `
        <div style="margin-top: 12px" ${this.protect_component}>
          The group rate is on "Shoulder Nights" known as +/-
          ${
            this.rfp.data().groupDateHonoredPre
              ? `
          <span>
            <span>
            <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900"
              ${this.protect_component} data-original-variable="{{room_type}}"
              >${this.rfp.data().groupDateHonoredPreDays}</span></span>
            pre
          </span>
          `
              : ""
          }
          ${
            this.rfp.data().groupDateHonoredPre &&
            this.rfp.data().groupDateHonoredPost
              ? `
          <span>&nbsp;and&nbsp;</span>`
              : ""
          }
          ${
            this.rfp.data().groupDateHonoredPost
              ? `
            <span>
            <span>
            <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900"
              ${this.protect_component} data-original-variable="{{room_type}}"
              >${this.rfp.data().groupDateHonoredPostDays}</span></span>
              post
            </span>
          `
              : ""
          }
          the "Peak Nights".
        </div>
        `
        : "";
    },
    getChangeMappedByTime(changes){
      let newChangesMap = {}
      for(let key in changes){
        newChangesMap[`${key}-${changes[key].item.time}`] = changes[key]
      }
      return newChangesMap;
    },
    init_changes(editor, event){
      this.editor = editor;
      this.acceptedChanges= {};
      this.hoteldeclinedChanges = {};
      this.crewfareDeclinedChanges= {};
      this.originalChanges = {}
      this.hotelChanges = {}
      this.crewfareChanges = {}
      this.newChanges = {}
      const flite = event.flite
      const changes = this.getChangeMappedByTime(flite.getChanges().changes)
      const {hotelChanges, crewfareChanges} = this.groupChangeByCreator(changes)
      this.originalChanges = {...changes}
      this.hotelChanges = {...hotelChanges}
      this.crewfareChanges = {...crewfareChanges}
      this.changesInitiated = true
    },
    findUnavailableChanges(oldChanges, newChanges){
      newChanges = {...newChanges, ...this.acceptedChanges, ...this.hoteldeclinedChanges, ...this.crewfareDeclinedChanges}
      const changes = {}
      for(let key in oldChanges){
        if(!newChanges[key]){
          changes[key] = {...oldChanges[key]}
        }
      }
      return changes;
    },
    findNewChanges(oldChanges, newChanges){
      console.log({oldChanges, newChanges})
      const changes = {}
      for(let key in newChanges){
        if(!oldChanges[key]){
          changes[key] = {...newChanges[key]}
        }
      }
      return changes;
    },
    toggleHasChanges(editor, event) {
      if(!this.changesInitiated){
        return;
      }
      const flite = event.flite
      const changes = this.getChangeMappedByTime(flite.getChanges().changes)
      for(let key in changes){
        delete this.acceptedChanges[key];
        delete this.crewfareDeclinedChanges[key];
        delete this.hoteldeclinedChanges[key];
      }
      const newChanges = this.findNewChanges(this.originalChanges, changes)
      this.newChanges = {...newChanges};
      this.editor = editor;
      this.changesInitiated = true;
    },
    acceptChange(editor, event){
      const flite = event.flite
      const changes = this.getChangeMappedByTime(flite.getChanges().changes)
      const {hotelChanges, crewfareChanges} = this.groupChangeByCreator(changes)
      const hotelChangesAccepted= this.findUnavailableChanges(this.hotelChanges, hotelChanges)
      const crewfareChangesAccepted = this.findUnavailableChanges(this.crewfareChanges, crewfareChanges)
      const myChanges = this.isHotel ? hotelChangesAccepted: crewfareChangesAccepted
      const otherChanges = this.isHotel ? crewfareChangesAccepted: hotelChangesAccepted
      if(!Object.keys(otherChanges).length){
        alert("You can't accept your own change")
      }
      else if(Object.keys(myChanges).length){
        alert("You can't accept your own change")
        window.location = ""
        return;
      }
      this.acceptedChanges = {
        ...this.acceptedChanges,
        ...otherChanges
      }
      
    },
    rejectChange(editor, event){
      const flite = event.flite
      const changes = this.getChangeMappedByTime(flite.getChanges().changes)
      const {hotelChanges, crewfareChanges} = this.groupChangeByCreator(changes)
      const hotelChangesRejected= this.findUnavailableChanges(this.hotelChanges, hotelChanges)
      const crewfareChangesRejected = this.findUnavailableChanges(this.crewfareChanges, crewfareChanges)
      this.hoteldeclinedChanges = {
        ...this.hoteldeclinedChanges,
        ...hotelChangesRejected
      }
      this.crewfareDeclinedChanges = {
        ...this.crewfareDeclinedChanges,
        ...crewfareChangesRejected
      }
    },
    groupChangeByCreator(changes){
      let hotelChanges = {}
      let crewfareChanges = {}
      for(let changeId in changes){
        const change = changes[changeId]
        if(change.item.userid === "1"){
          hotelChanges[changeId] = change
        }
        else{
          crewfareChanges[changeId] = change
        }
      }
      return {hotelChanges, crewfareChanges}
    },
    async updateSignatureCrewfare() {
      if (this.$refs.signaturePadCrewfareHolder) {
        const el = this.$refs.signaturePadCrewfareHolder;
        const options = {
          type: "dataURL",
        };
        const image = await this.$html2canvas(el, options);
        this.signatureCrewfare = image;
        this.crewfare_signature = this.signatureCrewfare;
      }
    },
    async updateSignatureHotel() {
      if (this.$refs.signaturePadHotelHolder) {
        const el = this.$refs.signaturePadHotelHolder;
        const options = {
          type: "dataURL",
        };
        const image = await this.$html2canvas(el, options);
        this.signatureHotel = image;
        this.hotel_signature = this.signatureHotel;
      }
    },
    undo(type) {
      if (type === "hotel") this.$refs.signaturePadHotel.undoSignature();
      else this.$refs.signaturePadCrewfare.undoSignature();
    },
    save(type) {
      this.savingSign = true;
      if (type === "hotel") {
        const { isEmpty, data } = this.$refs.signaturePadHotel.saveSignature();
        if (isEmpty) {
          this.$store.commit("setToast", {
            content: `<div class='flex flex-col gap-2'>
            Please add your signature.
          </div>`,
          });
          this.savingSign = false;
          return;
        }
        if (!isEmpty) {
          this.hotel_signature = data;
        }
      } else if (type === "crewfare") {
        const { isEmpty, data } =
          this.$refs.signaturePadCrewfare.saveSignature();
        if (isEmpty) {
          this.$store.commit("setToast", {
            content: `<div class='flex flex-col gap-2'>
            Please add your signature.
          </div>`,
          });
          this.savingSign = false;
          return;
        }
        if (!isEmpty) {
          this.crewfare_signature = data;
        }
      }
      this.savingSign = false;
      this.$store.commit("setToast", {
        content: `<div class='flex flex-col gap-2'>
            Signature saved!
          </div>`,
      });
    },
    toggleContractMethod({ content }) {
      this.$emit("toggleContractMethod");
    },
    async saveComments(editor) {
      this.has_comments = true;
      this.editor = editor;
    },
    async editContract() {
      const contract_content = this.editor.getContent();
      if(!contract_content){
        alert("No content found")
        return;
      }
      this.updatingContract = true;
      let data = {
        ...this.rfp.data(),
        isHotel: this.isHotel,
        contract_content,
      };

      if(this.has_changes){
          data.status = "Update Bid Terms"
          data = await setStatusHistory({
          data,
          content: "Contract updated",
          status: "Update Bid Terms",
        });
      }
      await setDoc(doc(firestore, "rfp_hotel", this.rfp.id), data);
      this.$store.commit("setToast", {
        content: `<div class='flex flex-col gap-2'>
              Changes has been sent!
            </div>`,
      });
      console.log("Updating data", data)
      this.updatingContract = false;
      this.has_comments = false;
      this.getData();
    },
    async acceptContractTerms() {
      const contract_content = this.editor.getContent();
      if(!contract_content){
        alert("No content found")
        return;
      }
      this.signingContract = true;
      let data = {
        ...this.rfp.data(),
        contract_content,
        status: "Accepted Contract Updates",
      };
      data = await setStatusHistory({
        data,
        content: "Contract changes accepted",
        status: "Accepted Contract Updates",
      });
      await setDoc(doc(firestore, "rfp_hotel", this.rfp.id), data);
      this.$store.commit("setToast", {
        content: `<div class='flex flex-col gap-2'>
              Contract is accepted!
            </div>`,
      });
      this.signingContract = false;
      this.getData();
      this.regenerateContract();
    },
    async getData() {
      this.contract_content = null;
      const rfpRef = doc(firestore, "rfp_hotel", this.$route.params.rfp);
      this.rfp = await getDoc(rfpRef);
      const parentRfpRef = doc(firestore, "rfps", this.rfp.data().rfp);
      this.parentRfp = await getDoc(parentRfpRef);
      const hotelRef = doc(firestore, "hotels", this.rfp.data().hotel_id);
      this.hotel = await getDoc(hotelRef);
      this.contractMethod = this.rfp.data().contractMethod;
      this.hotel_name = this.rfp.data().hotel_name || "";
      this.hotel_person_name = this.rfp.data().hotel_person_name || "";
      this.hotel_address = this.rfp.data().hotel_address || "";
      this.hotel_email = this.rfp.data().hotel_email || "";
      this.hotel_telephone = this.rfp.data().hotel_telephone || "";
      this.hotel_title = this.rfp.data().hotel_title || "";
      this.hotel_date =
        this.rfp.data().hotel_date || moment().format("dddd MMMM, DD, YYYY");
      this.hotel_taxes = this.rfp.data().hotel_taxes || "";
      this.hotel_other_fees = this.rfp.data().hotel_other_fees || "";
      this.crewfare_date =
        this.rfp.data().crewfare_date || moment().format("dddd MMMM, DD, YYYY");
      this.crewfare_members = this.rfp.data().crewfare_members || "";
      this.contract_content =
        this.rfp.data().contract_content || this.getDefaultContractContent;
      this.edit_mode = this.rfp.data().edit_mode || false;
      this.contract_history = this.rfp.data().contract_history || null;
      this.hotel_signature = this.rfp.data().hotel_signature || null;
      this.signed = this.rfp.data().signed || [];
      if (this.signed.includes("hotel")) {
        this.crewfare_name =
          this.rfp.data().crewfare_name || this.crewfare_name;
        this.crewfare_title =
          this.rfp.data().crewfare_title || this.crewfare_title;
        this.crewfare_signature =
          this.rfp.data().crewfare_signature || this.crewfare_signature;
      } else {
        this.crewfare_name = this.rfp.data().crewfare_name || "";
        this.crewfare_title = this.rfp.data().crewfare_title || "";
        this.crewfare_signature = this.rfp.data().crewfare_signature || "";
      }
      if (this.edit_mode) this.setEditor();
      setTimeout(() => {
        if (this.crewfare_signature && this.$refs.signaturePadCrewfare)
          this.$refs.signaturePadCrewfare.fromDataURL(this.crewfare_signature);
        if (this.hotel_signature && this.$refs.signaturePadHotel)
          this.$refs.signaturePadHotel.fromDataURL(this.hotel_signature);
      }, 1000);
      const contractEditableVariables = {
        //hotel_address: this.rfp.data().hotel_address || ""
      }
      for (const [key, value] of Object.entries(contractEditableVariables)) {
        this.contract_content = this.contract_content.replaceAll(`[[${key}]]`, value);
      }
    },
    async getContract() {
      this.hide_signature = true;
      this.regenerating = true;

      const date = moment(new Date()).format("YYYY-MM-DD-hh-mm-ss");
      const file = await fetch(this.contractDownloadAsJSONLink, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }).then((response) => response.json());

      this.regenerating = false;
      this.hide_signature = false;
      return file;
    },
    async regenerateContract() {
      this.regenerating = true;

      await this.getData();
      let data = this.rfp.data();
      if(data?.signed?.length !== 2){
        this.regenerating = false;
        return;
      }
      const pdf_data = await this.getContract();
      const fileContent = pdf_data.content;
      const fileName = pdf_data.name
      const name = fileName.split(".")[0];
      const executeEmail = import.meta.env.VITE_EXECUTE_MAIL || "";

      sendEmail({
        to: executeEmail,
        template: "emails.new-default",
        attachment: [
          {
            content: fileContent,
            disposition: "inline",
            filename: fileName,
            name: name,
            type: "application/pdf",
          },
        ],
        subject: processVariables(contractExecutedInternalSubject, this.rfp.data()),
        replyTo: this.rfp.data().replyTo,
        content: processVariables(contractExecutedInternalEmailContent, this.rfp.data()),
      });
      const qContacts = query(
        collection(firestore, "contacts"),
        where("hotel_id", "==", this.rfp.data().hotel_id)
      );
      const contacts = await getDocs(qContacts);
      const contactList = contacts.docs;
      const executiveCCs = import.meta.env.VITE_EXECUTIVE_CC_MAILS.split(',') || [];
      let emailsTo = unique([...this.relatedEmails, ...executiveCCs]);
      contactList.forEach((contact) => {
        emailsTo.push(contact.data().email.toLowerCase().trim());
      });
      const link = `${import.meta.env.VITE_URL}/rfp/${this.rfp.id}/contract`;
      emailsTo = unique(emailsTo);
      sendEmail({
        to: emailsTo,
        banner: this.rfp.data().banner,
        template: "emails.new-default",
        subject: processVariables(contractExecutedSubject, this.rfp.data()),
        replyTo: this.rfp.data().replyTo,
        content: processVariables(contractExecutedEmailContent, {...this.rfp.data(), link}),
        attachment: [
          {
            content: fileContent,
            disposition: "inline",
            filename: fileName,
            name: name,
            type: "application/pdf",
          },
        ],
      });

      this.regenerating = false;
    },
    async generatePDF() {
      this.regenerating = true
      this.hide_signature = true
      this.generatingPDF = true;
      this.hide_signature = true;
      const file = await fetch(this.contractDownloadAsJSONLink, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
      }).then((response) => response.json());

      this.regenerating = false;
      this.hide_signature = false;
      this.generatingPDF = false;
      return file;
    },
    async accept() {
      if (!this.isReadyToSign) {
        this.signErrorModal = true;
        return;
      }
      this.error = false;
      this.signingContract = true;
      let data = this.rfp.data();
      let message = "";
      let pdfLink = "";

      if (this.isHotel) {
        if (!this.signed.includes("hotel")) {
          this.signed.push("hotel");
        }
        data = {
          hotel_date: this.hotel_date,
          hotel_signature: this.hotel_signature,
          pdfLink,
          signed: this.signed,
          status:
            this.signed.length === 2
              ? "Signed by both sides"
              : "Signed by the hotel",
          hotel_signed_date: new Date(),
        };
        message = `${this.account.name} (${
          this.hotel.data().name
        }) just signed the contract for ${this.rfp.data().name}`;
        sendNotificationAdmin({
          type: "status_change",
          content: message,
          to: { name: "rfpsHotels", params: { id: this.rfp.data().rfp } },
        });
      } else {
        if (!this.signed.includes("crewfare")) {
          this.signed.push("crewfare");
        }
        data = {
          crewfare_title: this.crewfare_title,
          crewfare_date: this.crewfare_date,
          crewfare_name: this.crewfare_name,
          crewfare_signature: this.crewfare_signature,
          signed: this.signed,
          status:
            this.signed.length === 2
              ? "Signed by both sides"
              : "Signed by crewfare",
          crewfare_signed_date: new Date(),
        };
        message = `Crewfare just signed the contract for ${
          this.rfp.data().name
        }`;
        sendNotificationHotels({
          type: "status_change",
          ids: [this.rfp.data().hotel_id],
          content: message,
        });
      }
      const ccEmails = [...this.relatedEmails];
      if (this.signed.length === 1) {
        const link = `${import.meta.env.VITE_URL}/rfp/${this.rfp.id}/contract`;
        if (this.isHotel) {
          await sendEmail({
              banner: this.rfp.data().banner,
              to: ccEmails[0],
              template: "emails.new-default",
              replyTo: this.rfp.data().replyTo,
              banner: this.rfp.data().banner,
              subject: processVariables(contractSignedHotelSubject, this.rfp.data()),
              content: processVariables(contractSignedHotelEmailContent, {...this.rfp.data(), link}),
              cc: ccEmails.slice(1),
            });
        } else {
          const qContacts = query(
            collection(firestore, "contacts"),
            where("hotel_id", "==", this.rfp.data().hotel_id)
          );
          const contacts = await getDocs(qContacts);
          const contactList = contacts.docs;
          await Promise.all(contactList.map(async (contact) => {
            await sendEmail({
              banner: this.rfp.data().banner,
              template: "emails.new-default",
              to: contact.data().email,
              replyTo: this.rfp.data().replyTo,
              subject: processVariables(this.isHotel ? contractSignedHotelSubject: contractSignedInternalSubject, this.rfp.data()),
              content: processVariables(this.isHotel ? contractSignedHotelEmailContent : contractSignedInternalEmailContent, {...this.rfp.data(), link}),
            });
          }));
        }
        const hotelRef = doc(firestore, "hotels", this.rfp.data().hotel_id);
        const hotel = await getDoc(hotelRef);
        if (hotel.data().chain) {
          await sendNsoCommunication({
            chainName: hotel.data().chain,
            subject: this.isHotel ? contractSignedHotelSubject: contractSignedInternalSubject,
            content: this.isHotel ? contractSignedHotelEmailContent : contractSignedInternalEmailContent,
            variables: this.rfp.data(),
          });
        }
        this.saveUpdateContract({ data });
      } else {
        try {
          console.log("Signed length is not 1", this.signed.length)
          const rfpData = {
            ...this.rfp.data(),
            ...data
          };
          data = await setStatusHistory({
            data: rfpData,
            content: "Contract signed",
            status: data.status,
          });
          await setDoc(doc(firestore, "rfp_hotel", this.rfp.id), data);
          const pdf_data = await this.generatePDF();
          const fileContent = pdf_data.content;
          const fileName = pdf_data.name;
          const name = fileName.split(".")[0];
          const executeEmail = import.meta.env.VITE_EXECUTE_MAIL || "";
          console.log("sending to Execute email", executeEmail)

          await sendEmail({
            to: executeEmail,
            attachment: [
              {
                content: fileContent,
                disposition: "inline",
                filename: fileName,
                name: name,
                type: "application/pdf",
              },
            ],
            template: "emails.new-default",
            subject: processVariables(contractExecutedInternalSubject, this.rfp.data()),
            replyTo: this.rfp.data().replyTo,
            content: processVariables(contractExecutedInternalEmailContent, this.rfp.data()),
          });
          const qContacts = query(
            collection(firestore, "contacts"),
            where("hotel_id", "==", this.rfp.data().hotel_id)
          );
          const contacts = await getDocs(qContacts);
          const contactList = contacts.docs;
          const emailsTo = import.meta.env.VITE_EXECUTIVE_CC_MAILS.split(',') || [];
          emailsTo.push(...this.relatedEmails);
          contactList.forEach((contact) => {
            emailsTo.push(contact.data().email.toLowerCase().trim());
          });
          console.log("Sending team mail", emailsTo)
          const hotelRef = doc(firestore, "hotels", this.rfp.data().hotel_id);
          const hotel = await getDoc(hotelRef);
          if (hotel.data().chain) {
            const accounts = await getNsoByChain(hotel.data().chain);
            emailsTo.push(...accounts.map(account => account.email));
          }
          const link = `${import.meta.env.VITE_URL}/rfp/${this.rfp.id}/contract`;
          await sendEmail({
            banner: this.rfp.data().banner,
            template: "emails.new-default",
            to: emailsTo,
            subject: processVariables(contractExecutedSubject, this.rfp.data()),
            replyTo: this.rfp.data().replyTo,
            content: processVariables(contractExecutedEmailContent, {...this.rfp.data(), link}),
            attachment: [
              {
                content: fileContent,
                disposition: "inline",
                filename: fileName,
                name: name,
                type: "application/pdf",
              },
            ],
          });
        } catch (e) {
          console.error("Error in signing contract",e);
        }
      }
      this.loadingSigned = false;
      if (this.isHotel) {
        this.$store.commit("setToast", {
          content: `<div class='flex flex-col gap-2'>
                <div style="margin-top: 12px">The contract is signed! </div>
              </div>`,
        });
        router.push({
          name: "rfpContractSigned",
          params: { id: this.rfp.data().rfp },
        });
      } else {
        this.$store.commit("setToast", {
          content: `<div class='flex flex-col gap-2'>
                <div style="margin-top: 12px">The contract to ${
                  this.rfp.data().name
                } has been signed!</div>
              </div>`,
        });
        router.push({
          name: "rfpsHotels",
          params: { id: this.rfp.data().rfp },
        });
      }
      this.signingContract = false;
    },
    async saveUpdateContract({ data }) {
      const rfpData = {
        ...this.rfp.data(),
        ...data,
      };
      this.editHotelFields = false;
      const rfpDataStatusHistory = await setStatusHistory({
        data: rfpData,
        content: "Contract Signed",
        status: rfpData.status,
      });
      await setDoc(
        doc(firestore, "rfp_hotel", this.rfp.id),
        rfpDataStatusHistory
      );
    },
  },
};
</script>